import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";

import "../../styles/Checklists.css";
import { default as utilIcons } from "../../resources/utilIcons";

import { RestContext } from "../../App";
import { CHECKLIST_URL } from "../../serverConfig";
import BackButton from "../../components/BackButton";
import Checklist from "./Checklist";
import { formatDate, formatDatetime } from "../../util/timeUtils";
import PreviousChecklists from "./PreviousChecklists";
import { colors } from "../../styles/colors";
import { rotate } from "ol/transform";

function Checklists({ selectedSite }) {
    const [checklistList, setChecklistList] = useState([]);
    const [selectedChecklist, setSelectedChecklist] = useState();
    const [activeChecklist, setActiveChecklist] = useState();
    const [siteInv, setSiteInv] = useState({});
    const [instanceId, setInstanceId] = useState();
    const [viewPreviousChecklists, setViewPreviousChecklists] = useState(false);

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        if (selectedSite && selectedSite !== "all") {
            loadChecklistList();
            sendGetRequest(
                CHECKLIST_URL + `/inventory/${selectedSite}`,
                (response) => {
                    setSiteInv(response.data);
                }
            );
        }
    }, [selectedSite]);

    const loadChecklistList = () => {
        sendGetRequest(CHECKLIST_URL + `/site/${selectedSite}`, (response) => {
            setChecklistList(response.data.checklists);
        });
    };

    const clearSelectedChecklist = () => {
        setSelectedChecklist();
        loadChecklistList();
    };

    const resetChecklists = () => {
        setSelectedChecklist();
        setActiveChecklist();
        loadChecklistList();
    };

    const mapChecklists = () => {
        if (!checklistList) return null;
        return checklistList.map((checklist, index) => (
            <div
                key={"checklist-list-item-" + index}
                className="checklist-list-item"
                onClick={(event) => {
                    setSelectedChecklist(checklist);
                    setInstanceId(checklist.instanceId);
                }}
            >
                <div className="checklist-list-item-name">
                    {checklist.checklistName}
                </div>
                <div className="checklist-list-item-inprogress">
                    {checklist.instanceId !== null ? "In Progress" : null}
                </div>
                <div className="checklist-list-item-date">
                    {checklist.lastCompleted
                        ? "Last Completed " +
                          formatDate(checklist.lastCompleted)
                        : ""}
                </div>
            </div>
        ));
    };

    const getChecklistContent = () => {
        if (activeChecklist) {
            return (
                <Checklist
                    checklist={activeChecklist}
                    setActiveChecklist={setActiveChecklist}
                    instanceId={instanceId}
                    onComplete={resetChecklists}
                />
            );
        } else if (selectedChecklist) {
            return (
                <ChecklistStartMenu
                    checklist={selectedChecklist}
                    siteInv={siteInv}
                    clearSelectedChecklist={clearSelectedChecklist}
                    setActiveChecklist={setActiveChecklist}
                    selectedSite={selectedSite}
                    setInstanceId={setInstanceId}
                />
            );
        } else if (viewPreviousChecklists) {
            return (
                <div id="previous-checklists-wrapper">
                    <Button id="previous-checklists-back-button" onClick={() => setViewPreviousChecklists(false)}>
                        {utilIcons.caret(colors["ars-neutral-900"], {rotate: "90deg", width: '1.5em', height: '1.5em'})}{" Back"}
                    </Button>

                    <PreviousChecklists selectedSite={selectedSite} />
                </div>
            );
        } else {
            return (
                <>
                    <div id="checklist-list-wrapper">
                        {selectedSite === "all" ? (
                            <ChecklistAllSitesPlaceholder />
                        ) : !checklistList || checklistList.length === 0 ? (
                            <NoChecklistsPlaceholder />
                        ) : (
                            mapChecklists()
                        )}
                    </div>
                    <Button id="checklists-view-results-button" onClick={() => setViewPreviousChecklists(true)} disabled={selectedSite === "all"}>
                        View Previous Results
                    </Button>
                </>
            );
        }
    };

    return (
        <div id="checklists">
            <h2 id="checklists-header">Checklists</h2>
            {getChecklistContent()}
        </div>
    );
}

function ChecklistStartMenu({
    checklist,
    siteInv,
    clearSelectedChecklist,
    setActiveChecklist,
    selectedSite,
    setInstanceId,
}) {
    const [requirements, setRequirements] = useState([]);
    const [selectedInv, setSelectedInv] = useState({});
    const [instanceInfo, setInstanceInfo] = useState({});
    const [existingInstance, setExistingInstance] = useState(false);

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        if (checklist) {
            if (checklist.checklistRequirements) {
                setRequirements(
                    Array.from(
                        flattenRequirements(checklist.checklistRequirements)
                    )
                );
            }
            if (checklist.instanceId) {
                sendGetRequest(
                    CHECKLIST_URL + "/instanceData/" + checklist.instanceId,
                    (response) => {
                        setInstanceInfo({
                            dateStarted: response.data.dateStarted,
                            lastUser: response.data.lastUser,
                            lastUpdate: response.data.lastEdited,
                        });
                        setExistingInstance(response.data.data);
                    }
                );
            } else {
                setExistingInstance();
            }
        }
    }, [checklist]);

    useEffect(() => {
        if (requirements && siteInv) {
            const newSelectedInv = {};
            for (let req of requirements) {
                if (req in siteInv) {
                    if (siteInv[req].length === 1) {
                        newSelectedInv[req] = siteInv[req][0].inventoryId;
                    }
                }
            }
            setSelectedInv(newSelectedInv);
        }
    }, [requirements, siteInv]);

    useEffect(() => {
        console.log(selectedInv);
        console.log(requirements);
    }, [selectedInv]);

    const getRequirementOptions = (req) => {
        if (!siteInv || !siteInv[req]) return null;
        return siteInv[req].map((inv) => (
            <option
                value={inv.inventoryId}
            >{`${inv.instrumentFullName} - SN: ${inv.serialNumber}`}</option>
        ));
    };

    const getSelectedReq = (req) => {
        if (!selectedInv || !selectedInv[req]) return "null";
        return selectedInv[req];
    };

    const handleReqSelect = (event, req) => {
        const value = event.target.value;
        setSelectedInv((values) => ({
            ...values,
            [req]: value === "null" ? null : Number(value),
        }));
    };

    const getReqSelectDisabled = (req) => {
        if (!siteInv || !siteInv[req]) return true;
        return siteInv[req].length < 2;
    };

    const mapRequirements = () => {
        if (!requirements) return null;
        return requirements.map((req, index) => (
            <div className="checklist-start-menu-requirement">
                <div className="checklist-start-menu-requirement-type">
                    {req}
                </div>
                <select
                    className="checklist-start-menu-requirement-select"
                    value={getSelectedReq(req)}
                    onChange={(event) => handleReqSelect(event, req)}
                    disabled={getReqSelectDisabled(req)}
                >
                    <option value={"null"}>-</option>
                    {getRequirementOptions(req)}
                </select>
            </div>
        ));
    };

    const flattenRequirements = (requirements) => {
        if (!requirements) return null;
        const reqs = new Set();
        for (let req of requirements) {
            if (req.instrumentType) {
                reqs.add(req.instrumentType);
            }
            if (req.implied) {
                for (let impliedReq of flattenRequirements(req.implied)) {
                    reqs.add(impliedReq);
                }
            }
            if (req.groupRequirements) {
                for (let groupReq of flattenRequirements(
                    req.groupRequirements
                )) {
                    reqs.add(groupReq);
                }
            }
        }
        return reqs;
    };

    const validateRequirements = (reqs) => {
        if (!selectedInv || !reqs) return false;
        let mediumReqMet = null;
        for (let requirement of reqs) {
            if (requirement.groupRequirements) {
                switch (requirement.requirementType) {
                    case "soft":
                        break;
                    case "medium":
                        if (mediumReqMet !== true) {
                            if (
                                !validateRequirements(
                                    requirement.groupRequirements
                                )
                            ) {
                                mediumReqMet = false;
                                break;
                            } else {
                                mediumReqMet = true;
                            }
                        }
                        break;
                    case "hard":
                        if (
                            !validateRequirements(requirement.groupRequirements)
                        ) {
                            return false;
                        }
                        break;
                    default:
                        break;
                }
            } else {
                switch (requirement.requirementType) {
                    case "soft":
                        break;
                    case "medium":
                        if (mediumReqMet !== true) {
                            if (
                                requirement.instrumentType &&
                                !selectedInv[requirement.instrumentType]
                            ) {
                                mediumReqMet = false;
                                break;
                            } else {
                                mediumReqMet = true;
                            }
                        }
                        break;
                    case "hard":
                        if (!selectedInv[requirement.instrumentType]) {
                            return false;
                        }
                        break;
                    default:
                        break;
                }
                if (
                    selectedInv[requirement.instrumentType] &&
                    requirement.implied
                ) {
                    if (!validateRequirements(requirement.implied)) {
                        return false;
                    }
                }
            }
        }
        if (mediumReqMet === false) {
            return false;
        }
        return true;
    };

    const handleBackButton = (event) => {
        clearSelectedChecklist();
    };

    const handleSubmit = () => {
        if (!selectedInv || !checklist) return;
        const payload = {
            checklistId: checklist.checklistId,
            siteId: selectedSite,
            selectedInventory: existingInstance ? null : selectedInv,
        };

        sendPostRequest(
            CHECKLIST_URL + "/build",
            payload,
            (response) => {
                let newChecklist = response.data;
                newChecklist.instanceData = existingInstance;
                setActiveChecklist(newChecklist);
                setInstanceId(newChecklist.instanceId);
                clearSelectedChecklist();
            },
            (error) => {}
        );
    };

    return (
        <div id="checklist-start-menu">
            <div id="checklist-start-menu-header">
                <BackButton onClick={handleBackButton} />
                <span>{checklist.checklistName}</span>
            </div>
            <div
                id="checklist-start-menu-requirements-wrapper"
                hidden={existingInstance}
            >
                {mapRequirements()}
            </div>
            <div
                id="checklist-start-menu-instance-info"
                hidden={!existingInstance}
            >
                <table>
                    <tr>
                        <td>Date Started</td>
                        <td>{formatDatetime(instanceInfo.dateStarted)}</td>
                    </tr>
                    <tr>
                        <td>Last Update</td>
                        <td>
                            {formatDatetime(instanceInfo.lastUpdate)} by{" "}
                            <b>{instanceInfo.lastUser}</b>
                        </td>
                    </tr>
                </table>
            </div>
            <Button
                id="checklist-start-menu-start-button"
                className="submit-button"
                disabled={
                    !existingInstance &&
                    !validateRequirements(checklist.checklistRequirements)
                }
                onClick={handleSubmit}
            >
                {existingInstance
                    ? "Continue Checklist"
                    : "Start New Checklist"}
            </Button>
        </div>
    );
}

function ChecklistAllSitesPlaceholder() {
    return (
        <div className="checklist-list-placeholder">
            Select a site to view available checklists.
        </div>
    );
}

function NoChecklistsPlaceholder() {
    return (
        <div className="checklist-list-placeholder">
            No checklists found for this site.
        </div>
    );
}

export default Checklists;
