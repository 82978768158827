import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    Button,
    Collapse,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Spinner,
} from "reactstrap";

import { default as utilIcons } from "../../../resources/utilIcons";

import { ModalContext, RestContext } from "../../../App";
import { CHECKLIST_URL, INV_URL, SITE_URL } from "../../../serverConfig";
import { colors } from "../../../styles/colors";
import ChecklistInstrumentFamilyAdmin from "./ChecklistInstrumentFamilyAdmin";
import ChecklistInstrumentSubtypeAdmin from "./ChecklistInstrumentSubtypeAdmin";
import ChecklistPresetAdmin from "./ChecklistPresetAdmin";
import SearchableSelect from "../../../components/SearchableSelect";
import SortControls from "../../../components/SortControls";
import MultiSelect from "../../../components/MultiSelect";
import ChecklistSitesAdmin from "./ChecklistSitesAdmin";
import Checklist from "../../checklist/Checklist";
import BackButton from "../../../components/BackButton";
import ChecklistInputRangeAdmin from "./ChecklistInputRangeAdmin";
import ChecklistArchive from "./ChecklistArchive";

const ChecklistEditorContext = createContext(null);

function ChecklistAdmin() {
    const [currentTab, setCurrentTab] = useState("checklists");

    const renderCurrentTab = () => {
        switch (currentTab) {
            case "instrument-families":
                return <ChecklistInstrumentFamilyAdmin />;
            case "subtypes":
                return <ChecklistInstrumentSubtypeAdmin />;
            case "presets":
                return <ChecklistPresetAdmin />;
            case "checklists":
                return <ChecklistEditor />;
            case "sites":
                return <ChecklistSitesAdmin />;
            case "input-ranges":
                return <ChecklistInputRangeAdmin />;
            case "archive":
                return <ChecklistArchive />;
            default:
                return null;
        }
    };

    const changeTab = (event) => {
        setCurrentTab(event.target.getAttribute("tab-id"));
    };

    return (
        <div id="checklist-admin">
            <Nav id="checklist-admin-tabs" tabs>
                <NavItem
                    className="admin-tab"
                    id="checklist-admin-tab-checklists"
                >
                    <NavLink
                        tab-id="checklists"
                        active={currentTab === "checklists"}
                        onClick={changeTab}
                    >
                        Checklist Editor
                    </NavLink>
                </NavItem>
                <NavItem
                    className="admin-tab"
                    id="checklist-admin-tab-checklists"
                >
                    <NavLink
                        tab-id="sites"
                        active={currentTab === "sites"}
                        onClick={changeTab}
                    >
                        Sites
                    </NavLink>
                </NavItem>
                <NavItem
                    className="admin-tab"
                    id="checklist-admin-tab-input-ranges"
                >
                    <NavLink
                        tab-id="input-ranges"
                        active={currentTab === "input-ranges"}
                        onClick={changeTab}
                    >
                        Input Ranges
                    </NavLink>
                </NavItem>
                <NavItem className="admin-tab" id="checklist-admin-tab-presets">
                    <NavLink
                        tab-id="presets"
                        active={currentTab === "presets"}
                        onClick={changeTab}
                    >
                        Detail Presets
                    </NavLink>
                </NavItem>
                <NavItem
                    className="admin-tab"
                    id="checklist-admin-tab-instrument-families"
                >
                    <NavLink
                        tab-id="instrument-families"
                        active={currentTab === "instrument-families"}
                        onClick={changeTab}
                    >
                        Instrument Families
                    </NavLink>
                </NavItem>
                <NavItem
                    className="admin-tab"
                    id="checklist-admin-tab-subtypes"
                >
                    <NavLink
                        tab-id="subtypes"
                        active={currentTab === "subtypes"}
                        onClick={changeTab}
                    >
                        Subtypes
                    </NavLink>
                </NavItem>
                <NavItem className="admin-tab" id="checklist-admin-tab-archive">
                    <NavLink
                        tab-id="archive"
                        active={currentTab === "archive"}
                        onClick={changeTab}
                    >
                        Archive
                    </NavLink>
                </NavItem>
            </Nav>
            {renderCurrentTab()}
        </div>
    );
}

function ChecklistEditor() {
    const [checklistList, setChecklistList] = useState([]);
    const [instrumentTypes, setInstrumentTypes] = useState([]);
    const [combinedInv, setCombinedInv] = useState([]);
    const [conditionList, setConditionList] = useState([]);
    const [selectedChecklist, setSelectedChecklist] = useState();
    const [openInstruction, setOpenInstruction] = useState(null);
    const [checklistOptionsOpen, setChecklistOptionsOpen] = useState(false);
    const [checklistToDelete, setChecklistToDelete] = useState();
    const [checklistName, setChecklistName] = useState();
    const [requirements, setRequirements] = useState([]);
    const [requirementsOpen, setRequirementsOpen] = useState(true);
    const [siteAssignmentOpen, setSiteAssignmentOpen] = useState(true);
    const [checklistInstrumentTypes, setChecklistInstrumentTypes] = useState(
        []
    );
    const [inventoryPresets, setInventoryPresets] = useState({});
    const [instructions, setInstructions] = useState([]);
    const [previewChecklist, setPreviewChecklist] = useState({});
    const [changed, setChanged] = useState(false);
    const [checklistSites, setChecklistSites] = useState({});

    const { sendGetRequest, sendPostRequest, sendDeleteRequest } =
        useContext(RestContext);
    const { openModal, setOpenModal } = useContext(ModalContext);

    const toggleChecklistOptionsOpen = () =>
        setChecklistOptionsOpen(!checklistOptionsOpen);
    const toggleRequirementsOpen = () => setRequirementsOpen(!requirementsOpen);
    const toggleSiteAssignmentOpen = () => setSiteAssignmentOpen(!siteAssignmentOpen);
    const toggleCreateModal = () => {
        if (openModal === "checklist-create-modal") {
            setOpenModal();
        } else {
            setOpenModal("checklist-create-modal");
        }
    };
    const toggleRenameModal = () => {
        if (openModal === "checklist-rename-modal") {
            setOpenModal();
        } else {
            setOpenModal("checklist-rename-modal");
        }
    };
    const toggleDeleteModal = () => {
        if (openModal === "checklist-delete-modal") {
            setOpenModal();
        } else {
            setOpenModal("checklist-delete-modal");
        }
    };
    const toggleCopyModal = () => {
        if (openModal === "checklist-copy-modal") {
            setOpenModal();
        } else {
            setOpenModal("checklist-copy-modal");
        }
    };
    const togglePreviewModal = () => {
        if (openModal === "checklist-preview-modal") {
            setOpenModal();
        } else {
            const newChecklist = {
                checklistName: checklistName,
                requirements: requirements,
                instructions: instructions,
            };
            setPreviewChecklist(newChecklist);
            setOpenModal("checklist-preview-modal");
        }
    };

    useEffect(() => {
        loadChecklistList();
        sendGetRequest(CHECKLIST_URL + "/instrumentTypes", (response) => {
            setInstrumentTypes(response.data.sort(sortInstrumentTypes));
        });
        sendGetRequest(CHECKLIST_URL + "/combinedInv", (response) => {
            setCombinedInv(
                getCombinedInventoryList(
                    response.data.instruments,
                    response.data.families
                )
            );
        });
        sendGetRequest(CHECKLIST_URL + "/conditions", (response) => {
            setConditionList(response.data);
        });
    }, []);

    useEffect(() => {
        if (selectedChecklist) {
            sendGetRequest(
                CHECKLIST_URL + "/checklist/" + selectedChecklist,
                (response) => {
                    processChecklist(response.data);
                }
            );
        }
    }, [selectedChecklist]);

    useEffect(() => {
        if (!checklistInstrumentTypes) {
            return;
        } else {
            let newInstructions = [...instructions];
            for (let instruction of newInstructions) {
                instruction.requirements = instruction.requirements.filter(
                    (req) => checklistInstrumentTypes.includes(req)
                );
            }
            setInstructions(newInstructions);

            const newInvPresets = { ...inventoryPresets };
            for (let type of checklistInstrumentTypes) {
                if (!(type in inventoryPresets)) {
                    const formData = new FormData();
                    formData.append("instrumentType", type);
                    sendPostRequest(
                        CHECKLIST_URL + "/presets/type",
                        formData,
                        (response) => {
                            newInvPresets[type] =
                                response.data.inventoryPresets;
                        }
                    );
                }
            }
            setInventoryPresets(newInvPresets);
        }
    }, [checklistInstrumentTypes]);

    const sortInstrumentTypes = (a, b) => {
        return a.instrumentType.localeCompare(b.instrumentType);
    };

    const loadChecklistList = () => {
        sendGetRequest(CHECKLIST_URL + "/enabled", (response) => {
            setChecklistList(response.data.checklists);
        });
    };

    const processChecklist = (checklist) => {
        setChecklistName(checklist.checklistName);
        let newRequirements = [
            ...(checklist.singleRequirements
                ? checklist.singleRequirements
                : []),
            ...(checklist.requirementGroups ? checklist.requirementGroups : []),
        ];
        setRequirements(newRequirements);
        setChecklistInstrumentTypes(
            getChecklistInstrumentTypes(newRequirements)
        );
        setInstructions(checklist.instructions);

        const previewChecklist = {
            checklistName: checklist.checklistName,
            requirements: newRequirements,
            instructions: checklist.instructions,
        };
        setPreviewChecklist(previewChecklist);

        setChecklistSites(checklist.sites);
    };

    const getCurrentChecklist = () => {
        if (!selectedChecklist || !checklistList) return null;
        return checklistList.find(
            (checklist) => checklist.checklistId === selectedChecklist
        );
    };

    const getCombinedInventoryList = (instrumentList, familyList) => {
        let combinedList = [
            ...familyList.map((family) => ({
                combinedListId: family.familyId * -1,
                combinedListText: family.familyName,
                combinedListGroup: "Instrument Family",
            })),
            ...instrumentList.map((instrument) => ({
                combinedListId: instrument.instrumentId,
                combinedListText: instrument.instrumentName,
                combinedListGroup: instrument.instrumentType,
            })),
        ];

        return combinedList;
    };

    const handleDeleteChecklistClick = () => {
        const checklist = getCurrentChecklist();
        setChecklistToDelete(checklist);
        toggleDeleteModal();
    };

    const updateRequirements = (newRequirements) => {
        if (checklistInProgress()) return;
        setRequirements(newRequirements);
        setChanged(true);
    };

    const updateInstruction = (index, instruction) => {
        if (checklistInProgress()) return;
        let newInstructions = [...instructions];
        newInstructions[index] = instruction;
        setInstructions(newInstructions);
        setChanged(true);
    };

    const moveInstruction = (oldIndex, newIndex) => {
        let newInstructions = [...instructions];
        const instruction = newInstructions.splice(oldIndex, 1)[0];
        newInstructions.splice(newIndex, 0, instruction);
        setInstructions(newInstructions);
        if (openInstruction !== undefined) {
            setOpenInstruction(newIndex);
        }
        setChanged(true);
    };

    const createInstruction = () => {
        let newInstruction = {
            instructionText: "",
            inputs: [],
            requirements: [],
            presets: {},
            conditions: [],
        };
        let newInstructions = [...instructions, newInstruction];
        setInstructions(newInstructions);
    };

    const deleteInstruction = (index) => {
        let newInstructions = [...instructions];
        newInstructions.splice(index, 1);
        if (openInstruction === index) {
            setOpenInstruction();
        }
        setInstructions(newInstructions);
        setChanged(true);
    };

    const updateChecklistSites = (newChecklistSites) => {
        if (!newChecklistSites) return;
        setChanged(true);
        setChecklistSites(newChecklistSites);
    };

    const mapInstructions = () => {
        if (!instructions) return null;
        return instructions.map((instruction, index) => (
            <ChecklistEditorInstruction
                instruction={instruction}
                index={index}
                isOpen={openInstruction === index}
                setOpenInstruction={setOpenInstruction}
                updateInstruction={updateInstruction}
                moveInstruction={moveInstruction}
                deleteInstruction={deleteInstruction}
            />
        ));
    };

    const getChecklistInstrumentTypes = (requirements) => {
        let types = [];
        for (let requirement of requirements) {
            if (requirement.groupRequirements) {
                for (let groupReq of requirement.groupRequirements) {
                    types.push(groupReq.instrumentType);
                }
            } else {
                types.push(requirement.instrumentType);
            }
        }
        return types;
    };

    const createChecklist = (checklistName, checklistType) => {
        const payload = {
            checklistName: checklistType + " - " + checklistName,
            checklistType: checklistType,
            singleRequirements: [],
            requirementGroups: [],
            instructions: [],
        };

        sendPostRequest(CHECKLIST_URL + "/checklist", payload, (response) => {
            sendGetRequest(CHECKLIST_URL + "/all", (response) => {
                setChecklistList(response.data.checklists);
            });
            setOpenModal();
        });
    };

    const renameChecklist = (checklistId, checklistName) => {
        const formData = new FormData();
        formData.append("checklistId", checklistId);
        formData.append("checklistName", checklistName);

        sendPostRequest(
            CHECKLIST_URL + "/checklist/rename",
            formData,
            (response) => {
                sendGetRequest(CHECKLIST_URL + "/all", (response) => {
                    setChecklistList(response.data.checklists);
                });
                setChecklistName(checklistName);
                setOpenModal();
            }
        );
    };

    const deleteChecklist = (checklistId) => {
        sendDeleteRequest(
            CHECKLIST_URL + "/checklist/" + checklistId,
            (response) => {
                sendGetRequest(CHECKLIST_URL + "/all", (response) => {
                    setChecklistList(response.data.checklists);
                });
                setSelectedChecklist();
                setOpenModal();
            }
        );
    };

    const checklistInProgress = () => {
        if (!checklistSites) return false;
        for (let siteId in checklistSites) {
            if (checklistSites[siteId]) return true;
        }
        return false;
    };

    const handleSubmit = () => {
        const singleRequirements = [];
        const requirementGroups = [];
        for (let requirement of requirements) {
            if (requirement.groupRequirements) {
                requirementGroups.push(requirement);
            } else {
                singleRequirements.push(requirement);
            }
        }

        const payload = {
            checklistId: selectedChecklist,
            checklistName: checklistName,
            singleRequirements: singleRequirements,
            requirementGroups: requirementGroups,
            instructions: instructions,
            sites: checklistSites,
        };

        console.log(payload);
        sendPostRequest(CHECKLIST_URL + "/checklist", payload, (response) => {
            setChanged(false);
        });
    };

    return (
        <div id="checklist-editor">
            <ChecklistEditorContext.Provider
                value={{
                    instrumentTypes,
                    checklistInstrumentTypes,
                    getChecklistInstrumentTypes,
                    setChecklistInstrumentTypes,
                    combinedInv,
                    conditionList,
                    inventoryPresets,
                    checklistSites,
                    setChecklistSites: updateChecklistSites,
                    selectedChecklist: selectedChecklist,
                }}
            >
                <div id="checklist-editor-side">
                    <div className="checklist-admin-section-select-wrapper">
                        <Label for="checklist-editor-select">Checklist:</Label>
                        <SearchableSelect
                            name="checklist-editor-select"
                            data={checklistList}
                            selected={selectedChecklist}
                            setSelected={(value) =>
                                setSelectedChecklist(Number(value))
                            }
                            valField={"checklistId"}
                            textField={"checklistName"}
                        />
                        <Button
                            className="checklist-admin-new-button"
                            onClick={toggleCreateModal}
                        >
                            New
                        </Button>
                        <Dropdown
                            isOpen={checklistOptionsOpen}
                            toggle={toggleChecklistOptionsOpen}
                        >
                            <DropdownToggle
                                className="checklist-admin-options-toggle"
                                disabled={!selectedChecklist}
                            >
                                {utilIcons.vDots(colors["ars-neutral-400"])}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={toggleRenameModal}
                                    disabled={checklistInProgress()}
                                >
                                    Rename Checklist
                                </DropdownItem>
                                <DropdownItem onClick={toggleCopyModal}>
                                    Copy Checklist
                                </DropdownItem>
                                <DropdownItem
                                    style={{
                                        color: checklistInProgress()
                                            ? colors["ars-red-900"]
                                            : colors["ars-error"],
                                    }}
                                    onClick={handleDeleteChecklistClick}
                                    disabled={checklistInProgress()}
                                >
                                    Delete Checklist
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <div
                            id="checklist-uneditable-notification"
                            hidden={!checklistInProgress()}
                            style={{
                                color: colors["ars-error"],
                                maxWidth: "40%",
                                marginLeft: "auto",
                                textAlign: "right",
                            }}
                        >
                            Checklist currently in use. Complete/cancel all
                            active instances of this checklist to make changes.
                        </div>
                    </div>
                    <div id="checklist-editor-requirements-wrapper" hidden={!selectedChecklist}>
                        <div id="checklist-editor-requirements-header">
                            <div>Requirements</div>

                            <Button
                                className="checklist-editor-instruction-toggle"
                                onClick={toggleRequirementsOpen}
                            >
                                {utilIcons.caret(colors["ars-neutral-400"], {
                                    rotate: requirementsOpen ? "180deg" : null,
                                })}
                            </Button>
                        </div>
                        <Collapse isOpen={requirementsOpen}>
                            <ChecklistRequirementsEditor
                                id={"checklist-requirements-editor"}
                                requirements={requirements}
                                setRequirements={updateRequirements}
                            />
                        </Collapse>
                    </div>
                    <div id="checklist-editor-site-assignment-wrapper" hidden={!selectedChecklist}>
                        <div id="checklist-editor-site-assignment-header">
                            <div>Sites Assigned</div>

                            <Button
                                className="checklist-editor-instruction-toggle"
                                onClick={toggleSiteAssignmentOpen}
                            >
                                {utilIcons.caret(colors["ars-neutral-400"], {
                                    rotate: siteAssignmentOpen ? "180deg" : null,
                                })}
                            </Button>
                        </div>
                        <Collapse isOpen={siteAssignmentOpen}>
                            <ChecklistSiteAssignment
                                selectedChecklist={selectedChecklist}
                            />
                        </Collapse>
                    </div>
                </div>
                <div id="checklist-editor-main">
                    <div
                        id="checklist-editor-content-wrapper"
                        hidden={!selectedChecklist}
                        style={
                            checklistInProgress()
                                ? { overflow: "hidden" }
                                : null
                        }
                    >
                        <div
                            id="checklist-editor-block"
                            hidden={!checklistInProgress()}
                        />
                        <div id="checklist-editor-instructions-wrapper">
                            <div
                                id="checklist-editor-instructions-container"
                                hidden={!selectedChecklist}
                            >
                                {mapInstructions()}
                                <Button
                                    id="checklist-editor-create-instruction"
                                    onClick={createInstruction}
                                >
                                    New Instruction
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div
                        id="checklist-editor-footer"
                        hidden={!selectedChecklist}
                    >
                        <Button
                            className="submit-button"
                            onClick={handleSubmit}
                            disabled={!changed}
                        >
                            Save
                        </Button>
                        <Button
                            id="checklist-editor-preview-button"
                            onClick={togglePreviewModal}
                        >
                            Preview
                        </Button>
                    </div>
                    <ChecklistCreateModal
                        isOpen={openModal === "checklist-create-modal"}
                        toggle={toggleCreateModal}
                        createChecklist={createChecklist}
                    />
                    <ChecklistRenameModal
                        isOpen={openModal === "checklist-rename-modal"}
                        toggle={toggleRenameModal}
                        checklist={getCurrentChecklist()}
                        renameChecklist={renameChecklist}
                    />
                    <ChecklistDeleteModal
                        isOpen={openModal === "checklist-delete-modal"}
                        toggle={toggleDeleteModal}
                        checklist={checklistToDelete}
                        deleteChecklist={deleteChecklist}
                    />
                    <ChecklistCopyModal
                        isOpen={openModal === "checklist-copy-modal"}
                        toggle={toggleCopyModal}
                        checklist={getCurrentChecklist()}
                        onSuccess={loadChecklistList}
                    />
                    <ChecklistPreviewModal
                        isOpen={openModal === "checklist-preview-modal"}
                        toggle={togglePreviewModal}
                        checklist={previewChecklist}
                    />
                </div>
            </ChecklistEditorContext.Provider>
        </div>
    );
}

function ChecklistRequirementsEditor({ id, requirements, setRequirements }) {
    const [searchString, setSearchString] = useState("");
    const [active, setActive] = useState([]);
    const ref = useRef(null);
    const optionRef = useRef(null);
    const selectedRef = useRef(null);

    const { sendGetRequest } = useContext(RestContext);
    const {
        instrumentTypes,
        getChecklistInstrumentTypes,
        setChecklistInstrumentTypes,
    } = useContext(ChecklistEditorContext);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current) {
                if (
                    !ref.current.contains(event.target) &&
                    event.target.id !== "site-select"
                ) {
                    if (active.length > 0) setActive([]);
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("focusin", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("focusin", handleClickOutside);
        };
    }, [ref]);

    const getMatchingList = () => {
        let matchingData;
        if (searchString === "") {
            matchingData = instrumentTypes;
        } else {
            matchingData = instrumentTypes.filter((item) =>
                checkMatch(item.instrumentType)
            );
        }
        return matchingData;
    };

    const checkMatch = (item) => {
        if (searchString === "") return true;
        const regex = new RegExp(searchString, "i");
        return item.match(regex) !== null;
    };

    const checkRequirementForMatch = (requirement, instrumentType) => {
        if (requirement.instrumentType === instrumentType) {
            return true;
        } else {
            return false;
        }
    };

    const checkRequirementGroupForMatch = (
        requirementGroup,
        instrumentType
    ) => {
        for (let requirement of requirementGroup.groupRequirements) {
            if (checkRequirementForMatch(requirement, instrumentType)) {
                return true;
            }
        }
        return false;
    };

    const mapData = () => {
        if (!instrumentTypes || !requirements) return null;
        const matchingList = getMatchingList();
        return matchingList.map((type, index) => {
            if (
                requirements.some((requirement) => {
                    if (requirement.groupRequirements) {
                        return checkRequirementGroupForMatch(
                            requirement,
                            type.instrumentType
                        );
                    } else {
                        return checkRequirementForMatch(
                            requirement,
                            type.instrumentType
                        );
                    }
                })
            ) {
                return null;
            } else {
                return (
                    <div
                        className={
                            "multiselect-option" +
                            (active.some(
                                (active) =>
                                    !active.selected && active.index === index
                            )
                                ? " active"
                                : "")
                        }
                        value={type.instrumentType}
                        onClick={(event) =>
                            handleClick(
                                event,
                                index,
                                false,
                                type.instrumentType
                            )
                        }
                    >
                        {type.instrumentType}
                    </div>
                );
            }
        });
    };

    const mapRequirements = () => {
        if (!requirements) return null;
        return requirements.map((requirement, index) => {
            if (requirement.groupRequirements) {
                return (
                    <RequirementGroup
                        groupIndex={index}
                        requirement={requirement}
                        active={active}
                        updateRequirement={updateRequirement}
                        handleClick={handleClick}
                    />
                );
            } else {
                return (
                    <Requirement
                        index={index}
                        requirement={requirement}
                        active={active}
                        updateRequirement={updateRequirement}
                        handleClick={handleClick}
                    />
                );
            }
        });
    };

    const updateRequirement = (index, requirement) => {
        let newRequirements = [...requirements];
        newRequirements[index] = requirement;
        setRequirements(newRequirements);
    };

    const handleMouseDown = (event) => {
        if (event.detail > 1) {
            event.preventDefault();
        }
    };

    const handleClick = (
        event,
        index,
        selected,
        instrumentType,
        parentIndex = null
    ) => {
        if (event.ctrlKey) {
            for (let activeItem of active) {
                if (
                    activeItem.selected !== selected ||
                    (activeItem.index === index &&
                        activeItem.parentIndex === parentIndex)
                ) {
                    return;
                }
            }
            setActive([
                ...active,
                {
                    selected: selected,
                    index: index,
                    parentIndex: parentIndex,
                    instrumentType: instrumentType,
                },
            ]);
        } else if (event.shiftKey) {
        } else {
            setActive([
                {
                    selected: selected,
                    index: index,
                    parentIndex: parentIndex,
                    instrumentType: instrumentType,
                },
            ]);
        }
        const target = event.target;
        target.focus();
        if (
            target.offsetTop >=
            target.parentNode.scrollTop + target.parentNode.offsetHeight
        ) {
            target.scrollIntoView(false);
        } else if (target.offsetTop < target.parentNode.scrollTop) {
            target.scrollIntoView(true);
        }
    };

    const handleKey = (event) => {
        switch (event.key) {
            case "Enter":
                handleOptionEnter(event);
                break;
            case "Delete":
                handleRequirementDeleteKey(event);
                break;
            case "ArrowDown":
                handleArrowKey(event);
                break;
            case "ArrowUp":
                handleArrowKey(event);
                break;
            default:
                break;
        }
    };

    const handleOptionEnter = (event) => {
        if (event.key === "Enter") {
            if (
                optionRef.current &&
                optionRef.current === event.currentTarget
            ) {
                moveRight(event);
            }
        }
    };

    const handleRequirementDeleteKey = (event) => {
        if (event.key === "Delete") {
            if (
                selectedRef.current &&
                selectedRef.current === event.currentTarget
            ) {
                moveLeft(event);
            }
        }
    };

    const handleArrowKey = (event) => {
        if (event.key === "ArrowDown") {
            event.preventDefault();
            if (
                !active ||
                (selectedRef.current &&
                    event.currentTarget === selectedRef.current &&
                    !active.some((active) => active.selected)) ||
                (optionRef.current &&
                    event.currentTarget === optionRef.current &&
                    active.some((active) => active.selected))
            ) {
                const nextOption = findFirstOption(event.currentTarget);
                if (nextOption) nextOption.click();
            } else {
                const activeElement =
                    event.currentTarget.querySelector(".active");
                const activeIndex = Array.prototype.indexOf.call(
                    event.currentTarget.children,
                    activeElement
                );
                let nextOption = findNextOption(
                    event.currentTarget,
                    activeIndex
                );
                if (nextOption) nextOption.click();
            }
        } else if (event.key === "ArrowUp") {
            event.preventDefault();
            if (
                !active ||
                (selectedRef.current &&
                    event.currentTarget === selectedRef.current &&
                    active &&
                    !active.some((active) => active.selected)) ||
                (optionRef.current &&
                    event.currentTarget === optionRef.current &&
                    active.some((active) => active.selected))
            ) {
                const nextOption = findFirstOption(event.currentTarget);
                if (nextOption) nextOption.click();
            } else {
                const activeElement =
                    event.currentTarget.querySelector(".active");
                const activeIndex = Array.prototype.indexOf.call(
                    event.currentTarget.children,
                    activeElement
                );
                let previousOption = findPreviousOption(
                    event.currentTarget,
                    activeIndex
                );
                if (previousOption) previousOption.click();
            }
        } else {
            return;
        }
    };

    const findNextOption = (element, index) => {
        const children = element.children;
        const j = (index + 1) % children.length;
        const found = children[j];
        if (found.className.match("checklist-requirement-group-wrapper")) {
            return found.querySelector(".checklist-requirement-group");
        }
        return found;
    };

    const findPreviousOption = (element, index) => {
        const children = element.children;
        const j = (index + children.length - 1) % children.length;
        const found = children[j];
        if (found.className.match("checklist-requirement-group-wrapper")) {
            return found.querySelector(".checklist-requirement-group");
        }
        return found;
    };

    const findFirstOption = (element) => {
        const children = element.children;
        const found = children[0];
        if (found.className.match("checklist-requirement-group-wrapper")) {
            return found.querySelector(".checklist-requirement-group");
        }
        return found;
    };

    const handleChange = (event) => {
        setSearchString(event.target.value);
    };

    const moveRight = (event) => {
        if (!active || !optionRef.current) return;
        const newRequirements = [...requirements];
        let nextOption;
        for (let activeItem of active) {
            const activeElement = optionRef.current.querySelector(".active");
            const activeIndex = Array.prototype.indexOf.call(
                optionRef.current.children,
                activeElement
            );
            nextOption = findNextOption(optionRef.current, activeIndex);
            const foundType = instrumentTypes.find(
                (type) => type.instrumentType === activeItem.instrumentType
            );
            if (!foundType) {
                return;
            }
            newRequirements.push({
                instrumentType: activeItem.instrumentType,
                requirementType: "hard",
                implied: foundType.implied,
            });
        }
        setRequirements(newRequirements);
        setChecklistInstrumentTypes(
            getChecklistInstrumentTypes(newRequirements)
        );
        if (nextOption) {
            nextOption.click();
        } else if (selectedRef.current) {
            selectedRef.current.focus();
        }
    };

    const moveLeft = (event) => {
        if (!active || !selectedRef.current) return;

        let newRequirements = [...requirements];
        for (let activeIndex in active.sort((a, b) => {
            if (a.parentIndex === b.parentIndex) {
                return b.index - a.index;
            } else {
                return b.parentIndex - a.parentIndex;
            }
        })) {
            const activeItem = active[activeIndex];
            let deleteIndex = activeItem.index;
            let deleteParent = activeItem.parentIndex;

            let nextType;
            let nextIndex;
            let nextParentIndex;
            let nextSelected = true;
            if (activeItem.parentIndex) {
                const groupReqs =
                    requirements[activeItem.parentIndex].groupRequirements;
                const numChildren = groupReqs.length;
                if (numChildren > 1) {
                    nextParentIndex = activeItem.parentIndex;
                    if (activeItem.index === numChildren - 1) {
                        nextIndex = 0;
                    } else {
                        nextIndex = activeItem.index;
                    }
                    nextType = groupReqs[nextIndex].instrumentType;
                } else {
                    deleteIndex = activeItem.parentIndex;
                    deleteParent = null;
                    nextParentIndex = null;
                    if (requirements.length === 1) {
                        nextType = null;
                        nextIndex = null;
                        nextSelected = false;
                    } else {
                        nextIndex =
                            activeItem.index % (requirements.length - 1);
                        nextType = requirements[nextIndex].instrumentType;
                    }
                }
            } else {
                nextParentIndex = null;
                if (requirements.length === 1) {
                    nextType = null;
                    nextIndex = null;
                    nextSelected = false;
                } else {
                    nextIndex = activeItem.index % (requirements.length - 1);
                    nextType = requirements[nextIndex].instrumentType;
                }
            }

            if (deleteParent) {
                let newGroup = { ...requirements[deleteParent] };
                newGroup.groupRequirements.splice(deleteIndex, 1);
                newRequirements[deleteParent] = newGroup;
            } else {
                newRequirements.splice(deleteIndex, 1);
            }
            setRequirements(newRequirements);
            setChecklistInstrumentTypes(
                getChecklistInstrumentTypes(newRequirements)
            );

            if (Number(activeIndex) === active.length - 1) {
                if (nextSelected) {
                    setActive([
                        {
                            index: nextIndex,
                            selected: nextSelected,
                            parentIndex: nextParentIndex,
                            instrumentType: nextType,
                        },
                    ]);
                } else {
                    setActive([
                        {
                            index: 0,
                            selected: false,
                            parentIndex: null,
                            instrumentType: instrumentTypes[0].instrumentType,
                        },
                    ]);
                }
            }
        }
    };

    const createGroup = () => {
        let deleteIndexes = [];
        let newGroupReqs = [];
        let newRequirements = [...requirements];
        for (let activeItem of active) {
            deleteIndexes.push(activeItem.index);
            newGroupReqs.push(newRequirements[activeItem.index]);
        }
        for (let index of deleteIndexes.sort((a, b) => b - a)) {
            newRequirements.splice(index, 1);
        }
        newRequirements.push({
            requirementType: "hard",
            groupRequirements: newGroupReqs,
        });
        setRequirements(newRequirements);
        setActive([]);
    };

    const ungroup = () => {
        if (active.length === 0) return;
        let groupIndex =
            active[0].parentIndex !== null
                ? active[0].parentIndex
                : active[0].index;
        const ungroupedReqs = requirements[groupIndex].groupRequirements;
        let newRequirements = [...requirements];
        newRequirements.splice(groupIndex, 1, ...ungroupedReqs);
        setRequirements(newRequirements);
        const newActive = ungroupedReqs.map((req, index) => ({
            index: groupIndex + index,
            selected: true,
            instrumentType: req.instrumentType,
            parentIndex: null,
        }));
        setActive(newActive);
    };

    const leftDisabled = () => {
        return !(
            active &&
            active.some((active) => active.selected && active.index) !== null
        );
    };

    const rightDisabled = () => {
        return !(
            active &&
            active.some((active) => !active.selected && active.index) !== null
        );
    };

    const createGroupDisabled = () => {
        if (active.length < 2) return true;
        for (let activeItem of active) {
            if (
                !activeItem.selected ||
                activeItem.parentIndex !== null ||
                !activeItem.instrumentType
            ) {
                return true;
            }
        }
        return false;
    };

    const ungroupDisabled = () => {
        if (active.length === 0) return true;
        let groupIndex = null;
        for (let activeItem of active) {
            if (activeItem.parentIndex === null && activeItem.instrumentType) {
                return true;
            } else if (activeItem.parentIndex === null) {
                if (groupIndex !== null && activeItem.index !== groupIndex) {
                    return true;
                } else {
                    groupIndex = activeItem.index;
                }
            } else {
                if (
                    groupIndex !== null &&
                    activeItem.parentIndex !== groupIndex
                ) {
                    return true;
                } else {
                    groupIndex = activeItem.parentIndex;
                }
            }
        }
        return false;
    };

    return (
        <div id={id} className={"multiselect"} ref={ref}>
            <div className="multiselect-search-wrapper">
                <Input
                    placeholder="Search..."
                    onChange={handleChange}
                    value={searchString}
                />
            </div>
            <div className="multiselect-wrapper">
                <div className="multiselect-container-wrapper">
                    <div
                        className="multiselect-container"
                        tabIndex={0}
                        onKeyDown={handleKey}
                        ref={optionRef}
                    >
                        {mapData()}
                    </div>
                </div>
                <div className="multiselect-controls-wrapper">
                    <Button
                        className="multiselect-button"
                        onClick={moveRight}
                        disabled={rightDisabled()}
                        onMouseDown={(event) => event.preventDefault()}
                    >
                        {">"}
                    </Button>
                    <Button
                        className="multiselect-button"
                        onClick={moveLeft}
                        disabled={leftDisabled()}
                        onMouseDown={(event) => event.preventDefault()}
                    >
                        {"<"}
                    </Button>
                    <Button
                        className="multiselect-button"
                        onClick={createGroup}
                        disabled={createGroupDisabled()}
                    >
                        G
                    </Button>
                    <Button
                        className="multiselect-button"
                        onClick={ungroup}
                        disabled={ungroupDisabled()}
                    >
                        U
                    </Button>
                </div>
                <div className="multiselect-container-wrapper">
                    <div
                        className="checklist-requirements-container"
                        tabIndex={0}
                        onKeyDown={handleKey}
                        ref={selectedRef}
                    >
                        {mapRequirements()}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Requirement({
    index,
    requirement,
    updateRequirement,
    active,
    handleClick,
    parentIndex = null,
}) {
    const getInstrumentType = () => {
        if (!requirement || !requirement.instrumentType) return "";
        return requirement.instrumentType;
    };

    const updateFn = (requirement) => {
        updateRequirement(index, requirement);
    };

    const mapImpliedReqs = () => {
        if (!requirement.implied) return null;
        return requirement.implied.map((req, index) => (
            <div className="checklist-requirement-implied">
                {"* " + req.instrumentType}
            </div>
        ));
    };

    const handleRequirementClick = (event) => {
        handleClick(
            event,
            index,
            true,
            requirement.instrumentType,
            parentIndex
        );
    };

    return (
        <div
            className={
                "checklist-requirement-wrapper" +
                (active.some(
                    (active) =>
                        active.selected &&
                        active.parentIndex === parentIndex &&
                        active.index === index
                )
                    ? " active"
                    : "")
            }
            onClick={handleRequirementClick}
        >
            <div className="checklist-requirement">
                <div className="requirement-instrument-type">
                    {getInstrumentType()}
                </div>
                <RequirementTypeSwitch
                    requirement={requirement}
                    updateFn={updateFn}
                />
            </div>
            {mapImpliedReqs()}
        </div>
    );
}

function RequirementGroup({
    groupIndex,
    requirement,
    updateRequirement,
    active,
    handleClick,
}) {
    const updateFn = (requirement) => {
        updateRequirement(groupIndex, requirement);
    };

    const updateGroupRequirement = (reqIndex, groupReq) => {
        requirement.groupRequirements[reqIndex] = groupReq;
        updateRequirement(groupIndex, requirement);
    };

    const mapGroupRequirements = () => {
        if (!requirement.groupRequirements) return null;
        return requirement.groupRequirements.map((requirement, index) => (
            <Requirement
                index={index}
                requirement={requirement}
                active={active}
                updateRequirement={updateGroupRequirement}
                parentIndex={groupIndex}
                handleClick={handleClick}
            />
        ));
    };

    const handleRequirementGroupClick = (event) => {
        handleClick(event, groupIndex, true);
    };

    return (
        <div
            className={
                "checklist-requirement-group-wrapper" +
                (active.some(
                    (active) =>
                        active.selected &&
                        active.parentIndex === null &&
                        active.index === groupIndex
                )
                    ? " active"
                    : "")
            }
        >
            <div
                className="checklist-requirement-group"
                onClick={handleRequirementGroupClick}
            >
                <div className="requirement-group-label">Group</div>
                <RequirementTypeSwitch
                    requirement={requirement}
                    updateFn={updateFn}
                />
            </div>
            <div className="checklist-requirement-group-requirements-container">
                {mapGroupRequirements()}
            </div>
        </div>
    );
}

function RequirementTypeSwitch({ requirement, updateFn }) {
    const getReqType = () => {
        if (!requirement) return null;
        return requirement.requirementType;
    };

    const setReqType = (type) => {
        requirement.requirementType = type;
        updateFn(requirement);
    };

    const handleClickSoft = () => {
        setReqType("soft");
    };
    const handleClickMedium = () => {
        setReqType("medium");
    };
    const handleClickHard = () => {
        setReqType("hard");
    };

    return (
        <div className="requirement-type-switch">
            <Button
                className={
                    "requirement-type-switch-button" +
                    (getReqType() === "soft" ? " selected" : "")
                }
                onClick={handleClickSoft}
            >
                S
            </Button>
            <Button
                className={
                    "requirement-type-switch-button" +
                    (getReqType() === "medium" ? " selected" : "")
                }
                onClick={handleClickMedium}
            >
                M
            </Button>
            <Button
                className={
                    "requirement-type-switch-button" +
                    (getReqType() === "hard" ? " selected" : "")
                }
                onClick={handleClickHard}
            >
                H
            </Button>
        </div>
    );
}

function ChecklistEditorInstruction({
    instruction,
    index,
    isOpen,
    setOpenInstruction,
    updateInstruction,
    moveInstruction,
    deleteInstruction,
}) {
    const [optionsOpen, setOptionsOpen] = useState(false);

    const toggleOptions = () => setOptionsOpen(!optionsOpen);

    const getText = () => {
        if (!instruction || !instruction.instructionText) return "";
        return instruction.instructionText;
    };

    const handleClick = (event) => {
        setOpenInstruction(index);
    };

    const handleClose = (event) => {
        if (isOpen) {
            event.stopPropagation();
            setOpenInstruction(null);
        }
    };

    const handleTextChange = (event) => {
        let newInstruction = { ...instruction };
        newInstruction.instructionText = event.target.value;
        updateInstruction(index, newInstruction);
    };

    const handleDelete = () => {
        deleteInstruction(index);
    };

    const updateInputs = (inputs) => {
        let newInstruction = { ...instruction };
        newInstruction.inputs = inputs;
        updateInstruction(index, newInstruction);
    };

    const updateRequirements = (requirements) => {
        let newInstruction = { ...instruction };
        newInstruction.requirements = requirements;

        for (let input of newInstruction.inputs) {
            let newInputRequirements = [];
            for (let inputRequirement of input.requirements) {
                if (requirements.includes(inputRequirement)) {
                    newInputRequirements.push(inputRequirement);
                }
            }
            input.requirements = newInputRequirements;
        }
        updateInstruction(index, newInstruction);
    };

    const updatePresets = (presets) => {
        let newInstruction = { ...instruction };
        newInstruction.presets = presets;
        updateInstruction(index, newInstruction);
    };

    const updateConditions = (conditions) => {
        let newInstruction = { ...instruction };
        newInstruction.conditions = conditions;
        updateInstruction(index, newInstruction);
    };

    return (
        <div
            className={"checklist-editor-instruction" + (isOpen ? " open" : "")}
            onClick={handleClick}
        >
            <div className="checklist-editor-instruction-left-column">
                <SortControls
                    index={index}
                    updateFn={moveInstruction}
                    size={"medium"}
                />
            </div>
            <div className="checklist-editor-instruction-main-column">
                <div className="checklist-editor-instruction-header">
                    <div className="checklist-editor-instruction-index">
                        {index + 1 + ")"}
                    </div>
                    <ChecklistInstructionTextInput
                        value={getText()}
                        updateFn={handleTextChange}
                    />
                    <Dropdown isOpen={optionsOpen} toggle={toggleOptions}>
                        <DropdownToggle
                            className="checklist-admin-options-toggle"
                            style={{ marginRight: "-10px" }}
                        >
                            {utilIcons.vDots(colors["ars-neutral-400"])}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                style={{ color: colors["ars-error"] }}
                                onClick={handleDelete}
                            >
                                Delete Instruction
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Button
                        className="checklist-editor-instruction-toggle"
                        onClick={handleClose}
                    >
                        {utilIcons.caret(colors["ars-neutral-400"], {
                            rotate: isOpen ? "180deg" : null,
                        })}
                    </Button>
                </div>
                <Collapse isOpen={isOpen}>
                    <div className="checklist-editor-instruction-body">
                        <ChecklistInstructionRequirements
                            instruction={instruction}
                            updateRequirements={updateRequirements}
                        />
                        <ChecklistInstructionInputManager
                            instruction={instruction}
                            instructionIndex={index}
                            updateInputs={updateInputs}
                        />
                        <ChecklistInstructionPresetEditor
                            instruction={instruction}
                            updatePresets={updatePresets}
                        />
                        <ChecklistInstructionConditionManager
                            instruction={instruction}
                            updateConditions={updateConditions}
                        />
                    </div>
                </Collapse>
            </div>
        </div>
    );
}

function ChecklistInstructionTextInput({ value, updateFn }) {
    const [isFocused, setIsFocused] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current) {
                if (
                    !ref.current.contains(event.target) &&
                    event.target.id !== "site-select"
                ) {
                    setIsFocused(false);
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("focusin", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("focusin", handleClickOutside);
        };
    }, [ref]);

    const handleClick = (event) => {
        setIsFocused(true);
    };

    const getStyle = () => {
        if (isFocused || !ref.current) {
            return null;
        } else {
            const el = ref.current.querySelector(
                ".checklist-instruction-text-screen"
            );
            return { height: el.offsetHeight + "px" };
        }
    };

    return (
        <div
            className={
                "checklist-instruction-text-input-wrapper" +
                (isFocused ? " active" : "")
            }
            ref={ref}
            style={getStyle()}
        >
            <div className="checklist-instruction-text-screen">{value}</div>
            <textarea
                className={"checklist-instruction-text-input"}
                value={value}
                onChange={updateFn}
                onFocus={handleClick}
                placeholder="Instruction text..."
                spellCheck={false}
            />
        </div>
    );
}

function ChecklistInstructionRequirements({ instruction, updateRequirements }) {
    const [isOpen, setIsOpen] = useState(false);

    const { checklistInstrumentTypes } = useContext(ChecklistEditorContext);

    const toggle = () => setIsOpen(!isOpen);

    const getInstrumentTypes = () => {
        if (!checklistInstrumentTypes) return [];
        return checklistInstrumentTypes;
    };

    const getRequirements = () => {
        if (!instruction || !instruction.requirements) return [];
        return instruction.requirements;
    };

    return (
        <div className="checklist-editor-instruction-subsection-wrapper">
            <div className="checklist-editor-instruction-subheader">
                <div>Requirements</div>
                <Button
                    className="checklist-editor-instruction-toggle"
                    onClick={toggle}
                >
                    {utilIcons.caret(colors["ars-neutral-400"], {
                        rotate: isOpen ? "180deg" : null,
                    })}
                </Button>
            </div>
            <Collapse isOpen={isOpen}>
                <MultiSelect
                    className="checklist-editor-instruction-requirements-select"
                    data={getInstrumentTypes()}
                    selected={getRequirements()}
                    setSelected={updateRequirements}
                />
            </Collapse>
        </div>
    );
}

function ChecklistInstructionPresetEditor({ instruction, updatePresets }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const mapInstrumentTypes = () => {
        if (!instruction || !instruction.requirements) return null;
        return instruction.requirements.map((type) => (
            <ChecklistInstrumentPresetSelect
                type={type}
                selectedPresets={instruction.presets[type]}
                updateTypePresets={updateTypePresets}
            />
        ));
    };

    const updateTypePresets = (type, presets) => {
        let newPresets = { ...instruction.presets };
        newPresets[type] = presets;
        updatePresets(newPresets);
    };

    return (
        <div className="checklist-editor-instruction-subsection-wrapper">
            <div className="checklist-editor-instruction-subheader">
                <div>Presets</div>
                <Button
                    className="checklist-editor-instruction-toggle"
                    onClick={toggle}
                >
                    {utilIcons.caret(colors["ars-neutral-400"], {
                        rotate: isOpen ? "180deg" : null,
                    })}
                </Button>
            </div>

            <Collapse isOpen={isOpen}>
                <div className="checklist-editor-presets-wrapper">
                    {mapInstrumentTypes()}
                </div>
            </Collapse>
        </div>
    );
}

function ChecklistInstrumentPresetSelect({
    type,
    selectedPresets,
    updateTypePresets,
}) {
    const [presets, setPresets] = useState({});

    const { inventoryPresets } = useContext(ChecklistEditorContext);

    useEffect(() => {
        if (selectedPresets) {
            setPresets(selectedPresets);
        }
    }, [selectedPresets]);

    const handleSelect = (event, inventory) => {
        const value = event.target.value;
        let newSelectedInv = { ...presets };
        if (value === "null") {
            delete newSelectedInv[inventory];
        } else {
            newSelectedInv[inventory] = Number(value);
        }
        updateTypePresets(type, newSelectedInv);
    };

    const mapPresets = () => {
        if (!inventoryPresets[type] || !presets) return null;
        return Object.keys(inventoryPresets[type])
            .sort()
            .map((inventory, index) => (
                <div className="checklist-preset-select-row">
                    <div>{inventory}</div>
                    <select
                        disabled={
                            inventoryPresets[type][inventory].length === 0
                        }
                        value={presets[inventory]}
                        onChange={(event) => handleSelect(event, inventory)}
                    >
                        <option value={"null"}>-</option>
                        {inventoryPresets[type][inventory].map((preset) => (
                            <option value={preset.presetId}>
                                {preset.presetName}
                            </option>
                        ))}
                    </select>
                </div>
            ));
    };

    return (
        <div className="checklist-instrument-preset-manager">
            <div className="checklist-editor-instruction-subheader">
                <div>{type}</div>
            </div>
            <div className="checklist-instrument-presets-container">
                {mapPresets()}
            </div>
        </div>
    );
}

function ChecklistInstructionConditionManager({
    instruction,
    updateConditions,
}) {
    const [isOpen, setIsOpen] = useState(false);

    const { conditionList } = useContext(ChecklistEditorContext);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="checklist-editor-instruction-subsection-wrapper">
            <div className="checklist-editor-instruction-subheader">
                <div>Conditions</div>
                <Button
                    className="checklist-editor-instruction-toggle"
                    onClick={toggle}
                >
                    {utilIcons.caret(colors["ars-neutral-400"], {
                        rotate: isOpen ? "180deg" : null,
                    })}
                </Button>
            </div>
            <Collapse isOpen={isOpen}>
                <MultiSelect
                    data={conditionList}
                    selected={instruction.conditions}
                    setSelected={updateConditions}
                />
            </Collapse>
        </div>
    );
}

function ChecklistInstructionInputManager({
    instruction,
    instructionIndex,
    updateInputs,
}) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const updateInput = (index, input) => {
        const newInputs = [...instruction.inputs];
        newInputs[index] = input;
        updateInputs(newInputs);
    };

    const moveInput = (oldIndex, newIndex) => {
        const newInputs = [...instruction.inputs];
        const input = newInputs.splice(oldIndex, 1)[0];
        newInputs.splice(newIndex, 0, input);
        updateInputs(newInputs);
    };

    const deleteInput = (index) => {
        const newInputs = [...instruction.inputs];
        newInputs.splice(index, 1);
        updateInputs(newInputs);
    };

    const mapInputs = () => {
        if (
            !instruction ||
            !instruction.inputs ||
            instruction.inputs.length === 0
        ) {
            return (
                <div className="checklist-editor-inputs-placeholder">
                    No inputs found.
                </div>
            );
        }

        return instruction.inputs.map((input, index) => (
            <ChecklistInstructionInput
                input={input}
                index={index}
                instructionIndex={instructionIndex}
                instruction={instruction}
                updateInput={updateInput}
                moveFn={moveInput}
                deleteInput={deleteInput}
            />
        ));
    };

    const handleCreateNumberInput = () => {
        const newInput = { inputType: "number" };
        const newInputs = [...instruction.inputs, newInput];
        updateInputs(newInputs);
    };

    const handleCreateYesNoInput = () => {
        const newInput = { inputType: "yesno" };
        const newInputs = [...instruction.inputs, newInput];
        updateInputs(newInputs);
    };

    return (
        <div className="checklist-editor-instruction-subsection-wrapper">
            <div className="checklist-editor-instruction-subheader">
                <div>Inputs</div>
                <Button
                    className="checklist-editor-instruction-toggle"
                    onClick={toggle}
                >
                    {utilIcons.caret(colors["ars-neutral-400"], {
                        rotate: isOpen ? "180deg" : null,
                    })}
                </Button>
            </div>
            <Collapse isOpen={isOpen}>
                <div className="checklist-instruction-inputs-container">
                    {mapInputs()}
                </div>
                <div className="checklist-instruction-inputs-create-wrapper">
                    <Button
                        className="checklist-instruction-inputs-create-button"
                        onClick={handleCreateNumberInput}
                    >
                        Add number +
                    </Button>
                    <Button
                        className="checklist-instruction-inputs-create-button"
                        onClick={handleCreateYesNoInput}
                    >
                        Add yes/no +
                    </Button>
                </div>
            </Collapse>
        </div>
    );
}

function ChecklistInstructionInput({
    input,
    index,
    instructionIndex,
    instruction,
    updateInput,
    moveFn,
    deleteInput,
}) {
    const [optionsOpen, setOptionsOpen] = useState(false);

    const { openModal, setOpenModal } = useContext(ModalContext);

    const toggleOptions = () => setOptionsOpen(!optionsOpen);
    const toggleModal = () => {
        if (openModal === `input-config-modal-${instructionIndex}-${index}`) {
            setOpenModal();
        } else {
            setOpenModal(`input-config-modal-${instructionIndex}-${index}`);
        }
    };

    const getText = () => {
        if (!input || !input.inputText) return "";
        return input.inputText;
    };

    const handleTextChange = (event) => {
        let newInput = { ...input };
        newInput.inputText = event.target.value;
        updateInput(index, newInput);
    };

    const handleDelete = () => {
        deleteInput(index);
    };

    const handleConfigUpdate = (newInput) => {
        updateInput(index, newInput);
    };

    const getInputPreview = () => {
        switch (input.inputType) {
            case "number":
                return (
                    <div className="checklist-editor-input-preview">
                        <span>0.0</span>
                        <span>{input.inputUnit}</span>
                    </div>
                );
            case "yesno":
                return (
                    <div className="checklist-editor-input-preview">
                        <span>Yes</span>
                        <Input type="radio" disabled checked />
                        <span>No</span>
                        <Input type="radio" disabled />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="checklist-editor-instruction-input-wrapper">
            <SortControls index={index} updateFn={moveFn} size="medium" />
            <Input
                type="text"
                className="checklist-editor-instruction-input-text"
                placeholder="Input text (optional)"
                value={getText()}
                onChange={handleTextChange}
            />
            {getInputPreview()}
            <Dropdown
                isOpen={optionsOpen}
                toggle={toggleOptions}
                direction="end"
            >
                <DropdownToggle className="checklist-admin-options-toggle">
                    {utilIcons.vDots(colors["ars-neutral-400"])}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={toggleModal}>
                        Open Config...
                    </DropdownItem>
                    <DropdownItem
                        style={{ color: colors["ars-error"] }}
                        onClick={handleDelete}
                    >
                        Delete Input
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <ChecklistInputConfigModal
                isOpen={
                    openModal ===
                    `input-config-modal-${instructionIndex}-${index}`
                }
                toggle={toggleModal}
                input={input}
                instruction={instruction}
                updateInput={handleConfigUpdate}
            />
        </div>
    );
}

function ChecklistInputConfigModal({
    isOpen,
    toggle,
    input,
    instruction,
    updateInput,
}) {
    const [formData, setFormData] = useState({});
    const [useMinMax, setUseMinMax] = useState(false);
    const [changed, setChanged] = useState(false);
    const [parameterList, setParameterList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [calTypeList, setCalTypeList] = useState([]);
    const [valTypeList, setValTypeList] = useState([]);

    const { sendGetRequest } = useContext(RestContext);
    const { conditionList } = useContext(ChecklistEditorContext);

    const toggleMinMax = () => setUseMinMax(!useMinMax);

    useEffect(() => {
        sendGetRequest(CHECKLIST_URL + "/parameters/list", (response) => {
            setParameterList(
                [
                    "-",
                    ...response.data.map((parameter) => parameter.parameter),
                ].sort()
            );
        });
        sendGetRequest(CHECKLIST_URL + "/units", (response) => {
            setUnitList(
                ["-", ...response.data.map((unit) => unit.unitCode)].sort()
            );
        });
        sendGetRequest(CHECKLIST_URL + "/calTypes", (response) => {
            setCalTypeList(["-", ...response.data].sort());
        });
        sendGetRequest(CHECKLIST_URL + "/valTypes", (response) => {
            setValTypeList(["-", ...response.data].sort());
        });
    }, []);

    useEffect(() => {
        if (!useMinMax) {
            let newInput = { ...formData, minValue: null, maxValue: null };
            setFormData(newInput);
        }
    }, [useMinMax]);

    useEffect(() => {
        if (
            instruction &&
            instruction.requirements &&
            formData &&
            formData.requirements
        ) {
            const newRequirements = [];
            for (let requirement of formData.requirements) {
                if (instruction.requirements.includes(requirement)) {
                    newRequirements.push(requirement);
                }
            }
            setFormData((values) => ({
                ...values,
                requirements: newRequirements,
            }));
        }
    }, [instruction]);

    const getType = () => {
        if (!formData || !formData.inputType) return null;
        return formData.inputType;
    };

    const getUnit = () => {
        if (!formData || !formData.inputUnit) return "-";
        return formData.inputUnit;
    };

    const getMin = () => {
        if (!formData || !formData.minValue) return null;
        return formData.minValue;
    };

    const getMax = () => {
        if (!formData || !formData.maxValue) return null;
        return formData.maxValue;
    };

    const getCondition = () => {
        if (!formData || !formData.condition) return "null";
        return formData.condition;
    };

    const getRequirements = () => {
        if (!formData || !formData.requirements) return null;
        return formData.requirements;
    };

    const getParameter = () => {
        if (!formData || !formData.parameter) return "-";
        return formData.parameter;
    };

    const getCalType = () => {
        if (!formData || !formData.calType) return "-";
        return formData.calType;
    };

    const getValType = () => {
        if (!formData || !formData.valType) return "-";
        return formData.valType;
    };

    const handleOpen = () => {
        if (input && !changed) {
            setFormData({ ...input });
        }
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value === "" ? null : event.target.value;
        setFormData((values) => ({ ...values, [name]: value }));
        setChanged(true);
    };

    const handleParameter = (event) => {
        const value = event.target.value;
        setFormData((values) => ({
            ...values,
            parameter: value === "-" ? null : value,
        }));
        setChanged(true);
    };

    const handleUnit = (event) => {
        const value = event.target.value;
        setFormData((values) => ({
            ...values,
            inputUnit: value === "-" ? null : value,
        }));
        setChanged(true);
    };

    const handleCalType = (event) => {
        const value = event.target.value;
        setFormData((values) => ({
            ...values,
            calType: value === "-" ? null : value,
        }));
        setChanged(true);
    };

    const handleValType = (event) => {
        const value = event.target.value;
        setFormData((values) => ({
            ...values,
            valType: value === "-" ? null : value,
        }));
        setChanged(true);
    };

    const updateRequirements = (requirements) => {
        setFormData((values) => ({ ...values, requirements: requirements }));
        setChanged(true);
    };

    const handleSubmit = () => {
        const newInput = {
            inputType: formData.inputType,
            inputId: formData.inputId,
            inputText: formData.inputText,
            requirements: formData.requirements,
            calType: formData.calType,
            valType: formData.valType,
            parameter: formData.parameter,
        };

        if (formData.inputType === "number") {
            newInput.minValue =
                formData.minValue === null || formData.minValue === undefined
                    ? null
                    : Number(formData.minValue);
            newInput.maxValue =
                formData.maxValue === null || formData.maxValue === undefined
                    ? null
                    : Number(formData.maxValue);
            newInput.inputUnit = formData.inputUnit;
        } else if (formData.inputType === "yesno") {
            newInput.condition =
                formData.condition === "null" ? null : formData.condition;
        }

        updateInput(newInput);
        setChanged(false);
        toggle();
    };

    const mapConditions = () => {
        if (!conditionList) return null;
        return conditionList.map((condition, index) => (
            <option key={"input-config-modal-condition-" + index}>
                {condition}
            </option>
        ));
    };

    const mapParameters = () => {
        if (!parameterList) return null;
        return parameterList.map((parameter, index) => (
            <option key={"input-config-modal-parameter-" + index}>
                {parameter}
            </option>
        ));
    };

    const mapUnits = () => {
        if (!unitList) return null;
        return unitList.map((unit, index) => (
            <option key={"input-config-modal-unit-" + index}>{unit}</option>
        ));
    };

    const mapCalTypes = () => {
        if (!calTypeList) return null;
        return calTypeList.map((calType, index) => (
            <option key={"input-config-modal-calType-" + index}>
                {calType}
            </option>
        ));
    };

    const mapValTypes = () => {
        if (!valTypeList) return null;
        return valTypeList.map((valType, index) => (
            <option key={"input-config-modal-valType-" + index}>
                {valType}
            </option>
        ));
    };

    const getFieldsForInputType = () => {
        if (!formData || !formData.inputType) return null;
        switch (formData.inputType) {
            case "number":
                return (
                    <div className="input-config-modal-fields">
                        <div className="input-config-modal-field-wrapper">
                            <Label for="parameter">Parameter:</Label>
                            <select
                                name="parameter"
                                onChange={handleParameter}
                                value={getParameter()}
                            >
                                {mapParameters()}
                            </select>
                        </div>
                        <div className="input-config-modal-field-wrapper">
                            <Label for="inputUnit">Unit:</Label>
                            <select
                                name="inputUnit"
                                value={getUnit()}
                                onChange={handleUnit}
                            >
                                {mapUnits()}
                            </select>
                        </div>
                        <div className="input-config-modal-field-wrapper">
                            <Label for="calType">Cal Type:</Label>
                            <select
                                name="calType"
                                value={getCalType()}
                                onChange={handleCalType}
                            >
                                {mapCalTypes()}
                            </select>
                        </div>
                        <div className="input-config-modal-field-wrapper">
                            <Label for="valType">Val Type:</Label>
                            <select
                                name="valType"
                                value={getValType()}
                                onChange={handleValType}
                            >
                                {mapValTypes()}
                            </select>
                        </div>
                        <div className="input-config-modal-field-wrapper">
                            <Label for="useMinMax">Define Min/Max Value:</Label>
                            <Input
                                type="checkbox"
                                name="useMinMax"
                                checked={useMinMax}
                                onClick={toggleMinMax}
                                readOnly
                            />
                        </div>
                        <div
                            className="input-config-modal-field-wrapper"
                            hidden={!useMinMax}
                        >
                            <Label for="minValue">Min Value:</Label>
                            <Input
                                type="number"
                                step={0.1}
                                name="minValue"
                                value={getMin()}
                                onChange={handleChange}
                                placeholder="Min"
                            />
                        </div>
                        <div
                            className="input-config-modal-field-wrapper"
                            hidden={!useMinMax}
                        >
                            <Label for="maxValue">Max Value:</Label>
                            <Input
                                type="number"
                                step={0.1}
                                name="maxValue"
                                value={getMax()}
                                onChange={handleChange}
                                placeholder="Max"
                            />
                        </div>
                    </div>
                );
            case "yesno":
                return (
                    <div className="input-config-modal-fields">
                        <div className="input-config-modal-field-wrapper">
                            <Label for="parameter">Parameter:</Label>
                            <select
                                name="parameter"
                                onChange={handleParameter}
                                value={getParameter()}
                            >
                                {mapParameters()}
                            </select>
                        </div>
                        <div className="input-config-modal-field-wrapper">
                            <Label for="condition">Condition:</Label>
                            <select
                                name="condition"
                                value={getCondition()}
                                onChange={handleChange}
                            >
                                <option value="null"></option>
                                {mapConditions()}
                            </select>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            className="input-config-modal"
            isOpen={isOpen}
            toggle={toggle}
            onOpened={handleOpen}
        >
            <ModalHeader toggle={toggle}>Configure Input</ModalHeader>
            <ModalBody>
                <div className="input-config-modal-columns-wrapper">
                    <div className="input-config-modal-left-side">
                        <div className="input-config-modal-type-wrapper">
                            <Label>Input Type:</Label>
                            <div className="input-config-modal-type-switch">
                                <Button
                                    className={
                                        getType() === "number" ? "selected" : ""
                                    }
                                    onClick={() =>
                                        setFormData((values) => ({
                                            ...values,
                                            inputType: "number",
                                        }))
                                    }
                                >
                                    Number
                                </Button>
                                <Button
                                    className={
                                        getType() === "yesno" ? "selected" : ""
                                    }
                                    onClick={() =>
                                        setFormData((values) => ({
                                            ...values,
                                            inputType: "yesno",
                                        }))
                                    }
                                >
                                    Yes/No
                                </Button>
                            </div>
                        </div>
                        {getFieldsForInputType()}
                    </div>
                    <div className="input-config-modal-right-side">
                        <div className="input-config-modal-right-side-header">
                            Requirements
                        </div>
                        <MultiSelect
                            data={instruction.requirements}
                            selected={getRequirements()}
                            setSelected={updateRequirements}
                        />
                    </div>
                </div>

                <Button
                    className="submit-button"
                    onClick={handleSubmit}
                    disabled={!changed}
                >
                    Save
                </Button>
            </ModalBody>
        </Modal>
    );
}

function ChecklistCreateModal({ isOpen, toggle, createChecklist }) {
    const [nameString, setNameString] = useState("");
    const [selectedType, setSelectedType] = useState();
    const [typeList, setTypeList] = useState([]);

    const { sendGetRequest } = useContext(RestContext);

    useEffect(() => {
        sendGetRequest(CHECKLIST_URL + "/types", (response) => {
            setTypeList(response.data);
            setSelectedType(response.data[0]);
        });
    }, []);

    const mapTypesToOption = () => {
        if (!typeList) return null;
        return typeList.map((type, index) => (
            <option key={"checklist-create-modal-type-" + index}>{type}</option>
        ));
    };

    const handleChange = (event) => {
        setNameString(event.target.value);
    };

    const handleType = (event) => {
        console.log(event.target.value);
        setSelectedType(event.target.value);
    };

    const handleSubmit = (event) => {
        createChecklist(nameString, selectedType);
    };

    const handleClosed = () => {
        setNameString("");
    };

    return (
        <Modal
            className="checklist-create-modal"
            isOpen={isOpen}
            toggle={toggle}
            onClosed={handleClosed}
        >
            <ModalHeader toggle={toggle}>Create New Checklist</ModalHeader>
            <ModalBody>
                <div className="checklist-modal-field-wrapper">
                    <Label>Checklist Type</Label>
                    <select value={selectedType} onChange={handleType}>
                        {mapTypesToOption()}
                    </select>
                </div>
                <div className="checklist-modal-field-wrapper">
                    <Label>Checklist Name</Label>
                    <InputGroup>
                        <InputGroupText>
                            {selectedType ? selectedType + " - " : ""}
                        </InputGroupText>
                        <Input
                            value={nameString}
                            onChange={handleChange}
                            placeholder="Checklist name..."
                        />
                    </InputGroup>
                </div>
                <Button className="submit-button" onClick={handleSubmit}>
                    Create
                </Button>
            </ModalBody>
        </Modal>
    );
}

function ChecklistRenameModal({ isOpen, toggle, checklist, renameChecklist }) {
    const [nameString, setNameString] = useState("");

    const getChecklistName = () => {
        if (!checklist) return "";
        return checklist.checklistName;
    };

    const getChecklistType = () => {
        if (!checklist || !checklist.checklistType) return null;
        return checklist.checklistType;
    };

    const extractChecklistName = () => {
        if (!checklist || !checklist.checklistName) return "";
        return checklist.checklistName.substring(
            checklist.checklistName.indexOf("-") + 2
        );
    };

    const handleChange = (event) => {
        setNameString(event.target.value);
    };

    const handleSubmit = (event) => {
        renameChecklist(
            checklist.checklistId,
            getChecklistType() + " - " + nameString
        );
    };

    const handleOpened = () => {
        setNameString(extractChecklistName());
    };

    const handleClosed = () => {
        setNameString("");
    };

    return (
        <Modal
            className="checklist-rename-modal"
            isOpen={isOpen}
            toggle={toggle}
            onOpened={handleOpened}
            onClosed={handleClosed}
        >
            <ModalHeader toggle={toggle}>Change Checklist Name</ModalHeader>
            <ModalBody>
                <div>
                    <Label>Old Name:</Label>
                    <div>{getChecklistName()}</div>
                </div>
                <div>
                    <Label>New Name:</Label>
                    <InputGroup>
                        <InputGroupText>
                            {getChecklistType()
                                ? getChecklistType() + " - "
                                : ""}
                        </InputGroupText>
                        <Input
                            value={nameString}
                            onChange={handleChange}
                            placeholder="Checklist name..."
                        />
                    </InputGroup>
                </div>
                <Button
                    className="submit-button"
                    onClick={handleSubmit}
                    disabled={nameString.length === 0}
                >
                    Save
                </Button>
            </ModalBody>
        </Modal>
    );
}

function ChecklistDeleteModal({ isOpen, toggle, checklist, deleteChecklist }) {
    const getChecklistName = () => {
        if (!checklist) return "";
        return checklist.checklistName;
    };

    const handleClick = () => {
        if (!checklist) return;
        deleteChecklist(checklist.checklistId);
    };

    return (
        <Modal
            className="checklist-delete-modal"
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalBody>
                <div>
                    {"Are you sure you want to permanently delete "}
                    <b>{getChecklistName()}</b>
                    {"?"}
                </div>
                <div className="checklist-delete-modal-buttons">
                    <Button
                        className="checklist-delete-modal-delete"
                        onClick={handleClick}
                    >
                        Delete
                    </Button>
                    <Button
                        className="checklist-delete-modal-cancel"
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

function ChecklistCopyModal({ isOpen, toggle, checklist, onSuccess }) {
    const [checklistName, setChecklistName] = useState("");
    const [waiting, setWaiting] = useState(false);
    const { sendPostRequest } = useContext(RestContext);

    useEffect(() => {
        init();
    }, [checklist]);

    const init = () => {
        if (checklist && checklist.checklistName) {
            setChecklistName(extractChecklistName());
        }
    };

    const getChecklistName = () => {
        if (!checklist || !checklist.checklistName) return null;
        return checklist.checklistName;
    };

    const getChecklistType = () => {
        if (!checklist || !checklist.checklistType) return null;
        return checklist.checklistType;
    };

    const extractChecklistName = () => {
        if (!checklist || !checklist.checklistName) return "";
        return checklist.checklistName.substring(
            checklist.checklistName.indexOf("-") + 2
        );
    };

    const handleChange = (event) => {
        setChecklistName(event.target.value);
    };

    const handleSubmit = () => {
        if (waiting || !checklistName || !checklist || !checklist.checklistId)
            return;
        const formData = new FormData();
        formData.append("checklistId", checklist.checklistId);
        formData.append(
            "checklistName",
            getChecklistType() + " - " + checklistName
        );
        setWaiting(true);
        sendPostRequest(
            CHECKLIST_URL + "/checklist/copy",
            formData,
            (response) => {
                onSuccess();
                toggle();
                setWaiting(false);
            },
            (error) => {
                setWaiting(false);
            }
        );
    };

    return (
        <Modal
            className="checklist-copy-modal"
            isOpen={isOpen}
            toggle={toggle}
            onOpened={init}
        >
            <ModalHeader toggle={toggle}>Copy Checklist</ModalHeader>
            <ModalBody>
                <div>
                    <Label>Checklist to Copy:</Label>
                    <div>{getChecklistName()}</div>
                </div>
                <div>
                    <Label>New Checklist Name:</Label>
                    <InputGroup>
                        <InputGroupText>
                            {getChecklistType()
                                ? getChecklistType() + " - "
                                : ""}
                        </InputGroupText>
                        <Input
                            value={checklistName}
                            onChange={handleChange}
                            placeholder="Checklist name..."
                        />
                    </InputGroup>
                </div>
                <Button
                    className="submit-button"
                    onClick={handleSubmit}
                    disabled={!checklist || checklistName.length < 1}
                >
                    {waiting ? <Spinner size="sm" /> : "Submit"}
                </Button>
            </ModalBody>
        </Modal>
    );
}

function ChecklistPreviewModal({ isOpen, toggle, checklist }) {
    const [previewChecklist, setPreviewChecklist] = useState();
    const [bodyContent, setBodyContent] = useState();

    useEffect(() => {
        if (previewChecklist) {
            setBodyContent(
                <Checklist
                    checklist={previewChecklist}
                    setActiveChecklist={() => setPreviewChecklist()}
                    handleSubmit={() => null}
                />
            );
        } else {
            setBodyContent(
                <ChecklistPreviewStartMenu
                    checklist={checklist}
                    toggle={toggle}
                    setPreviewChecklist={setPreviewChecklist}
                />
            );
        }
    }, [previewChecklist, checklist]);

    return (
        <Modal
            id="checklist-editor-preview-modal"
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalBody>{bodyContent}</ModalBody>
        </Modal>
    );
}

function ChecklistPreviewStartMenu({ checklist, setPreviewChecklist }) {
    const [requirements, setRequirements] = useState([]);
    const [allInv, setAllInv] = useState([]);
    const [selectedInv, setSelectedInv] = useState({});

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);
    const { openModal, setOpenModal } = useContext(ModalContext);

    const toggle = () => {
        if (openModal === "checklist-preview-modal") {
            setOpenModal();
        } else {
            setOpenModal("checklist-preview-modal");
        }
    };

    useEffect(() => {
        sendGetRequest(CHECKLIST_URL + "/instrumentsByType", (response) => {
            setAllInv(response.data);
        });
    }, []);

    useEffect(() => {
        if (checklist && checklist.requirements) {
            setRequirements(
                Array.from(flattenRequirements(checklist.requirements))
            );
        }
    }, [checklist]);

    const getChecklistName = () => {
        if (!checklist || !checklist.checklistName) return "";
        return checklist.checklistName;
    };

    const getChecklistRequirements = () => {
        if (!checklist || !checklist.requirements) return [];
        return checklist.requirements;
    };

    const getRequirementOptions = (req) => {
        if (!allInv || !allInv[req]) return null;
        return allInv[req]
            .sort((a, b) => a.instrumentName.localeCompare(b.instrumentName))
            .map((inv) => (
                <option value={inv.instrumentId}>{inv.instrumentName}</option>
            ));
    };

    const getSelectedReq = (req) => {
        if (!selectedInv || !selectedInv[req]) return "null";
        return selectedInv[req];
    };

    const handleReqSelect = (event, req) => {
        const value = event.target.value;
        setSelectedInv((values) => ({
            ...values,
            [req]: value === "null" ? undefined : Number(value),
        }));
    };

    const getReqSelectDisabled = (req) => {
        if (!allInv || !allInv[req]) return true;
        return allInv[req].length < 2;
    };

    const mapRequirements = () => {
        if (!requirements) return null;
        return requirements.map((req, index) => (
            <div className="checklist-start-menu-requirement">
                <div className="checklist-start-menu-requirement-type">
                    {req}
                </div>
                <select
                    className="checklist-start-menu-requirement-select"
                    value={getSelectedReq(req)}
                    onChange={(event) => handleReqSelect(event, req)}
                    disabled={getReqSelectDisabled(req)}
                >
                    <option value={"null"}>-</option>
                    {getRequirementOptions(req)}
                </select>
            </div>
        ));
    };

    const flattenRequirements = (requirements) => {
        if (!requirements) return null;
        const reqs = new Set();
        for (let req of requirements) {
            if (req.instrumentType) {
                reqs.add(req.instrumentType);
            }
            if (req.implied) {
                for (let impliedReq of flattenRequirements(req.implied)) {
                    reqs.add(impliedReq);
                }
            }
            if (req.groupRequirements) {
                for (let groupReq of flattenRequirements(
                    req.groupRequirements
                )) {
                    reqs.add(groupReq);
                }
            }
        }
        return reqs;
    };

    const validateRequirements = (reqs) => {
        if (!selectedInv || !reqs) return false;
        let mediumReqMet = null;
        for (let requirement of reqs) {
            if (requirement.groupRequirements) {
                switch (requirement.requirementType) {
                    case "soft":
                        break;
                    case "medium":
                        if (mediumReqMet !== true) {
                            if (
                                !validateRequirements(
                                    requirement.groupRequirements
                                )
                            ) {
                                mediumReqMet = false;
                                break;
                            } else {
                                mediumReqMet = true;
                            }
                        }
                        break;
                    case "hard":
                        if (
                            !validateRequirements(requirement.groupRequirements)
                        ) {
                            return false;
                        }
                        break;
                    default:
                        break;
                }
            } else {
                switch (requirement.requirementType) {
                    case "soft":
                        break;
                    case "medium":
                        if (mediumReqMet !== true) {
                            if (
                                requirement.instrumentType &&
                                !selectedInv[requirement.instrumentType]
                            ) {
                                mediumReqMet = false;
                                break;
                            } else {
                                mediumReqMet = true;
                            }
                        }
                        break;
                    case "hard":
                        if (!selectedInv[requirement.instrumentType]) {
                            return false;
                        }
                        break;
                    default:
                        break;
                }
                if (
                    selectedInv[requirement.instrumentType] &&
                    requirement.implied
                ) {
                    if (!validateRequirements(requirement.implied)) {
                        return false;
                    }
                }
            }
        }
        if (mediumReqMet === false) {
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (!selectedInv || !checklist) return;

        const singleRequirements = [];
        const requirementGroups = [];

        for (let requirement of checklist.requirements) {
            if (requirement.groupRequirements) {
                requirementGroups.push(requirement);
            } else {
                singleRequirements.push(requirement);
            }
        }

        const payload = {
            checklistName: checklist.checklistName,
            singleRequirements: singleRequirements,
            requirementGroups: requirementGroups,
            instructions: checklist.instructions,
            selectedInstruments: selectedInv,
        };

        sendPostRequest(
            CHECKLIST_URL + "/build/preview",
            payload,
            (response) => {
                setPreviewChecklist(response.data);
            }
        );
    };

    return (
        <div id="checklist-start-menu">
            <div id="checklist-start-menu-header">
                <BackButton onClick={toggle} />
                <span>{getChecklistName()}</span>
            </div>
            <div id="checklist-start-menu-requirements-wrapper">
                {mapRequirements()}
            </div>
            <Button
                id="checklist-start-menu-start-button"
                className="submit-button"
                disabled={!validateRequirements(getChecklistRequirements())}
                onClick={handleSubmit}
            >
                Preview Checklist
            </Button>
        </div>
    );
}

function ChecklistSiteAssignment({}) {
    const [siteList, setSiteList] = useState([]);

    const { sendGetRequest } = useContext(RestContext);
    const { checklistSites, setChecklistSites, selectedChecklist } = useContext(
        ChecklistEditorContext
    );

    useEffect(() => {
        sendGetRequest(SITE_URL + "/list", (response) => {
            const newSiteList = response.data.map((site) => ({
                siteId: site.siteId,
                siteText: `${site.siteAbbr} - ${site.fullName}`,
                siteGroup: site.agencyName,
            }));
            setSiteList(newSiteList);
        });
    }, []);

    const getChecklistSites = () => {
        if (!checklistSites || !siteList) return [];
        const sites = siteList.filter((site) => site.siteId in checklistSites);
        return sites;
    };

    const setSelected = (sites) => {
        let newChecklistSites = {};
        for (let site of sites) {
            if (site.siteId in checklistSites) {
                newChecklistSites[site.siteId] = checklistSites[site.siteId];
            } else {
                newChecklistSites[site.siteId] = false;
            }
        }

        setChecklistSites(newChecklistSites);
    };

    return (
        <div id="checklist-editor-site-assignment" hidden={!selectedChecklist}>
            <MultiSelect
                data={siteList}
                selected={getChecklistSites()}
                setSelected={setSelected}
                textField={"siteText"}
                valField={"siteId"}
                groupField={"siteGroup"}
                SelectedComponent={SelectedSite}
            />
        </div>
    );
}

function SelectedSite({ item, className, value, onClick, onMouseDown }) {
    const { checklistSites } = useContext(ChecklistEditorContext);

    const getText = () => {
        if (!item || !item.siteText) return null;
        return item.siteText;
    };

    const inUse = () => {
        if (!item || !item.siteId || !checklistSites) return false;
        return checklistSites[item.siteId];
    };

    return (
        <div
            className={
                "multiselect-option assigned-site" +
                (className ? " " + className : "") +
                (inUse() ? " disabled" : "")
            }
            value={value}
            onClick={onClick}
            onMouseDown={onMouseDown}
        >
            <div className="checklist-site-name">{getText()}</div>
            <div className="checklist-site-in-use" hidden={!inUse()}>
                In use
            </div>
        </div>
    );
}

export default ChecklistAdmin;
