import React, { useContext, useEffect, useState } from "react";

import '../styles/SiteTree.css';

import { SITE_URL } from "../serverConfig";
import { RestContext } from "../App";

function SiteTree(props) {
    const [sites, setSites] = useState({});
    
    const { sendGetRequest } = useContext(RestContext);

    useEffect(() => {
        sendGetRequest(SITE_URL + '/siteTree', (response) => {
            setSites(response.data);
        });
    }, []);

    const buildTree = () => {
        if (sites === undefined) return null;
        let tree = [];
        let ids = Object.keys(sites);
        ids.sort((a,b) => sites[a].value < sites[b].value ? -1 : 1);
        for (let id of ids) {
            if (Number(id) >= 100) {
                continue;
            } else {
                tree.push(...buildBranch(sites[id]))
            }
        }
        return tree;
    }

    const getItemClass = (node) => {
        if (node.parentId === null) return "agency";
        if (node.leaf) return "leaf";
        return "group";
    }

    const getSubTree = (node) => {
        let subTree = [node.nodeId];
        if (node.leaf) return subTree;
        console.log(node);
        for (let child of node.children) {
            subTree = subTree.concat(getSubTree(sites[child]));
        }
        console.log(subTree);
        return subTree;
    }

    const getParent = (node) => {
        if (!sites || node.parentId === null) return null;
        return sites[node.parentId];
    }

    const buildBranch = (node) => {
        let branch = [buildLine(node)];
        if (node.children.length !== 0) {
            let childElements = node.children.map((child) => buildBranch(sites[child]));
            branch.push(<ul key={'site-tree-children-' + node.nodeId}>{childElements}</ul>);
        }
        return branch;
    }

    const buildLine = (node) => {
        if (props.lineComponent === undefined) {
            return <li key={'site-tree-branch-' + node.nodeId} className={getItemClass(node)} getSubTree={getSubTree} getParent={getParent}>{node.value}</li>;
        } else {
            return <props.lineComponent key={'site-tree-branch-' + node.nodeId} node={node} getSubTree={getSubTree} getParent={getParent} {...props} />
        }
    }

    return (
        <ul>
            {buildTree()}
        </ul>
    )
}

export default SiteTree;