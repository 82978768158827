import React, { useContext, useEffect, useState } from "react";

import { default as user } from "../../resources/user.svg";
import { default as backArrow } from "../../resources/back-svgrepo-com.svg";
import { default as arrow } from "../../resources/left-arrow-svgrepo-com.svg";
import { default as fileIcon } from "../../resources/files-primary-svgrepo-com.svg";
import { default as bell } from "../../resources/bell-svgrepo-com.svg";

import { DOC_URL, STATIONLOG_URL } from "../../serverConfig";
import BackButton from "../../components/BackButton";
import { Button, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Spinner } from "reactstrap";
import { AttachmentSelect } from "./StationLogIssueEditor";
import DocumentPreview from "../../components/DocumentPreview";
import { RestContext } from "../../App";
import { formatDatetimeWithLocalTimezone } from "../../util/timeUtils";

function StationLogIssue({ selectedIssue, setSelectedIssue, refresh }) {
    const [issue, setIssue] = useState();
    const [attachments, setAttachments] = useState([]);
    const [replies, setReplies] = useState([]);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [previewFile, setPreviewFile] = useState();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [people, setPeople] = useState([]);

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    const toggleEditorOpen = () => setEditorOpen(!editorOpen);

    const togglePreviewOpen = () => setPreviewOpen(!previewOpen);

    useEffect(() => {
        if (selectedIssue !== undefined) {
            sendGetRequest(STATIONLOG_URL + '/issue/' + selectedIssue, (response) => {
                setIssue(response.data);
                setLastPage(0);
                setLoading(false);
            });
        }
    }, [selectedIssue]);

    useEffect(() => {
        if (issue !== undefined) {
            setAttachments(issue.attachments);
            sendGetRequest(STATIONLOG_URL + '/people/' + issue.siteId, (response) => {
                setPeople(response.data.sort((a, b) => a.fullName > b.fullName ? 1 : -1));
            });
        }
    }, [issue]);

    useEffect(() => {
        if (issue !== undefined) {
            sendPostRequest(STATIONLOG_URL + '/replies/' + issue.logId, { pageNumber: lastPage, pageSize: 20 }, (response) => {
                setReplies(response.data);
            });
        }
    }, [people]);

    useEffect(() => {
        if (lastPage !== 0) {
            setLoading(true);
            sendPostRequest(STATIONLOG_URL + '/replies/' + issue.logId, { pageNumber: lastPage, pageSize: 20 }, (response) => {
                if (response.data.length === 0) {
                    setLoading(null);
                } else {
                    setReplies(replies.concat(response.data));
                    setLoading(false);
                }
            });
        }
    }, [lastPage]);

    const getTitle = () => {
        if (issue === undefined) return null;
        return issue.title;
    }

    const getAbbr = () => {
        if (issue === undefined) return null;
        return issue.siteAbbr;
    }

    const getDetail = () => {
        if (issue === undefined) return null;
        return issue.detail;
    }

    const getTimestamp = () => {
        if (issue === undefined) return null;
        return formatDatetimeWithLocalTimezone(issue.recordDate);
    };

    const getName = () => {
        if (issue === undefined) return null;
        return issue.author;
    }

    const getLogId = () => {
        if (issue === undefined) return null;
        return issue.logId;
    }

    const mapReplies = () => {
        return replies.map((reply, index) =>
            <StationLogReply
                key={'station-log-reply-' + index}
                reply={reply}
                refresh={refreshReplies}
                setPreviewFile={setPreviewFile}
                togglePreviewOpen={togglePreviewOpen}
                people={people}
            />
        )
    }

    const mapAttachments = () => {
        return attachments.map((file, index) =>
            <Attachment key={'station-log-issue-attachment-' + index} file={file} setPreviewFile={setPreviewFile} togglePreviewOpen={togglePreviewOpen} />
        );
    }

    const handleScroll = (event) => {
        const atBottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight <= 20;
        if (atBottom && loading === false) {
            setLastPage(lastPage + 1);
        }

    }

    const refreshReplies = () => {
        sendPostRequest(STATIONLOG_URL + '/replies/' + issue.logId, { pageNumber: 0, pageSize: (lastPage + 1) * 20 }, (response) => {
            setReplies(response.data);
            setLastPage(lastPage + 1);
        });
    }

    const doRefresh = () => {
        refreshReplies();
        refresh();
    }

    return (
        <div id='station-log-issue-page' onScroll={handleScroll}>
            <div id='station-log-issue-wrapper'>
                <div id="station-log-issue-header">
                    <BackButton onClick={() => setSelectedIssue()} />
                    <div id='station-log-issue-header-text'>
                        <div id='station-log-issue-title'>{getTitle()}</div>
                        <div id="station-log-issue-abbr">{getAbbr()}</div>
                    </div>
                </div>
                <div id="station-log-issue-info">
                    <img src={user} className='user-icon' alt='User Icon' />
                    <span id="station-log-issue-username" className="station-log-username">{getName()}</span> at <span>{getTimestamp()}</span>
                </div>
                <div id='station-log-issue-body'>{getDetail()}</div>
                <div className='station-log-issue-attachments'>
                    {mapAttachments()}
                </div>
                <div id="station-log-issue-reply-button" onClick={toggleEditorOpen}><img className="inline-svg back-arrow" src={backArrow} alt="Back Arrow" /><span>Reply</span></div>
                <ReplyEditor
                    isOpen={editorOpen}
                    parentId={getLogId()}
                    refresh={doRefresh}
                    toggle={toggleEditorOpen}
                    people={people}
                />
            </div>
            <div id='station-log-issue-replies'>
                <h3>Replies</h3>
                {mapReplies()}
                <NoReplies hidden={replies.length > 0} />
                <Spinner hidden={!loading} />
            </div>
            <DocumentPreview isOpen={previewOpen} toggle={togglePreviewOpen} doc={previewFile} />
        </div>
    )
}

function StationLogReply({ reply, setPreviewFile, togglePreviewOpen, refresh, people }) {
    const [editorOpen, setEditorOpen] = useState(false);

    const toggleEditorOpen = () => setEditorOpen(!editorOpen);

    const getTimestamp = () => {
        if (reply === undefined) return null;
        return formatDatetimeWithLocalTimezone(reply.recordDate);
    };

    const getName = () => {
        return reply.author;
    }

    const mapAttachments = () => {
        if (reply === undefined) return null;
        return reply.attachments.map((file, index) =>
            <Attachment key={'station-log-issue-attachment-' + index} file={file} setPreviewFile={setPreviewFile} togglePreviewOpen={togglePreviewOpen} />
        );
    }

    return (
        <div className="station-log-reply-wrapper">
            {[...Array(Math.min(reply.entryLevel - 1, 5))].map((e, index) => <div className="station-log-reply-spacer" key={'spacer-' + index}></div>)}
            <div className="station-log-reply" reply-level={reply.entryLevel}>
                <div className="station-log-reply-info">
                    <img src={user} className='user-icon' alt='User Icon' />
                    <span className="station-log-reply-username">{getName()}</span> at <span>{getTimestamp()}</span>
                </div>
                <div className="station-log-reply-body">{reply.detail}</div>
                <div className='station-log-issue-attachments'>
                    {mapAttachments()}
                </div>
                <div className="station-log-reply-reply-button" onClick={toggleEditorOpen}><img className="inline-svg back-arrow" src={backArrow} alt="Back Arrow" /><span>Reply</span></div>
                <ReplyEditor
                    isOpen={editorOpen}
                    parentId={reply.logId}
                    refresh={refresh}
                    toggle={toggleEditorOpen}
                    people={people}
                />
            </div>
        </div>

    )
}

function ReplyEditor({ people, parentId, refresh, toggle, isOpen }) {
    const [formData, setFormData] = useState({ detail: "", people: new Set() });
    const [attachments, setAttachments] = useState([]);
    const [notifyMenuOpen, setNotifyMenuOpen] = useState(false);
    const [replyPeople, setReplyPeople] = useState([]);
    const [waiting, setWaiting] = useState(false);

    const { sendPostRequest } = useContext(RestContext);

    const toggleNotifyMenu = () => setNotifyMenuOpen(!notifyMenuOpen);

    useEffect(() => {
        setReplyPeople(people);
    }, [people]);

    const handleSubmit = () => {
        const form = new FormData();
        form.append("parentId", parentId);
        form.append("detail", formData['detail']);
        for (let file of attachments) {
            form.append('files', file);
        }
        for (let person of formData['people']) {
            form.append('people', person);
        }
        setWaiting(true);
        sendPostRequest(STATIONLOG_URL + '/replies/new', form, (response) => {
            setWaiting(false);
            setFormData({ detail: "", people: new Set() });
            refresh();
            toggle();
        }, (error) => {
            setWaiting(false);
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value }));
    }

    const deleteAttachment = (file) => {
        const fileName = file.name;
        let newAttachments = [...attachments];
        for (let index in newAttachments) {
            if (newAttachments[index].name !== fileName) {
                continue;
            }
            newAttachments.splice(index, 1);
            break;
        }
        setAttachments(newAttachments);
    }

    const canSubmit = () => {
        return formData.detail !== "";
    }

    const handlePeople = (person) => {
        const value = Number(person.userId);
        let newPeople = new Set(formData.people);
        if (newPeople.has(value)) {
            newPeople.delete(value)
        } else {
            newPeople.add(value);
        }
        setFormData(values => ({ ...values, people: newPeople }));
    }

    const personChecked = (person) => {
        if (formData === undefined) return false;
        const result = formData.people.has(person.userId);
        return result;
    }

    const mapPeopleToDropdownItem = () => {
        if (replyPeople === undefined) return null;
        return replyPeople.map((person, index) =>
            <DropdownItem
                key={'people-dropdown-' + index}
                className="station-log-search-people-row"
                value={person.userId}
                onClick={() => { handlePeople(person) }}
                toggle={false}
            >
                {person.fullName}
                <Input type="checkbox" value={personChecked(person)} checked={personChecked(person)} readOnly />
            </DropdownItem>
        );
    }

    const notifyButtonDisabled = () => {
        return (false);
    }

    return (
        <Collapse className="station-log-reply-editor" isOpen={isOpen}>
            <div className="station-log-reply-editor-detail-wrapper">
                <Input className="station-log-reply-editor-detail" type="textarea" name='detail' value={formData.detail} onChange={handleChange} placeholder="Reply text..." />
                <div className='station-log-reply-editor-detail-counter'>{formData.detail.length + ' / 2000'}</div>
            </div>
            <div className="station-log-reply-editor-footer">
                <AttachmentSelect attachments={attachments} setAttachments={setAttachments} delete={deleteAttachment} />
                <Dropdown className="station-log-reply-editor-notify-dropdown" isOpen={notifyMenuOpen} toggle={toggleNotifyMenu}>
                    <DropdownToggle className='station-log-reply-editor-notify-button' color="primary" disabled={notifyButtonDisabled() || replyPeople === undefined || replyPeople.length === 0}><img src={bell} alt="Notification Icon" /></DropdownToggle>
                    <DropdownMenu>
                        {mapPeopleToDropdownItem()}
                    </DropdownMenu>
                </Dropdown>
                <Button className="station-log-reply-editor-submit" onClick={handleSubmit} disabled={!canSubmit()}>
                    {waiting ? <Spinner size={'sm'} /> : <img className="inline-svg right-arrow" src={arrow} alt="Right Arrow" />}
                </Button>
            </div>
        </Collapse>
    )
}

function NoReplies({ hidden }) {
    return (
        <div id='station-log-no-replies' hidden={hidden}>
            No replies yet.
        </div>
    )
}

function Attachment({ file, setPreviewFile, togglePreviewOpen }) {

    const handleClick = (event) => {
        event.preventDefault();
        setPreviewFile(file);
        togglePreviewOpen();
    }

    const getThumbnail = () => {
        if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(file.extension)) {
            return <img className='station-log-attachment-thumbnail' src={DOC_URL + '/preview/' + file.docId} alt="Preview" />
        } else {
            return <img className='station-log-attachment-thumbnail-icon' src={fileIcon} alt="File Icon" />
        }
    }

    return (
        <div className="station-log-attachment" onClick={handleClick}>
            {getThumbnail()}
            <span className="station-log-attachment-name">{file.docName + '.' + file.extension}</span>
        </div>
    )
}

export default StationLogIssue;