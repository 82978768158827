import React, { createContext, useContext, useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupText,
    Modal,
    ModalBody,
    ModalHeader,
    Spinner,
    UncontrolledDropdown,
} from "reactstrap";

import "../../styles/StationLog.css";
import { default as user } from "../../resources/user.svg";
import { default as plus } from "../../resources/plus-svgrepo-com.svg";

import StationLogIssue from "./StationLogIssue";
import { STATIONLOG_URL } from "../../serverConfig";
import { today, last90, formatDatetimeWithLocalTimezone } from "../../util/timeUtils";
import { checkMobile } from "../../util/globals";
import StationLogIssueEditor from "./StationLogIssueEditor";
import { RestContext } from "../../App";

export const StationLogContext = createContext(null);

function StationLog({ selectedSite, siteDetails }) {
    const [issues, setIssues] = useState([]);
    const [editorOpen, setEditorOpen] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState();
    const [recentItems, setRecentItems] = useState([]);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [atEnd, setAtEnd] = useState(false);
    const [filters, setFilters] = useState({
        searchString: null,
        startDate: last90(),
        endDate: today(),
        people: new Set(),
        pageSize: 20,
    });

    const { sendPostRequest } = useContext(RestContext);

    const toggleEditorOpen = () => setEditorOpen(!editorOpen);

    useEffect(() => {
        if (selectedSite !== undefined) {
            setFilters((values) => ({
                ...values,
                searchString: null,
                people: new Set(),
            }));
            setLastPage(0);
            loadRecent();
        }
    }, [selectedSite]);

    useEffect(() => {
        setIssues([]);
        setAtEnd(false);
        if (lastPage === 0) {
            setLoading(true);
            loadIssues();
        } else {
            setLastPage(0);
        }
    }, [filters]);

    useEffect(() => {
        setLoading(true);
        loadIssues();
    }, [lastPage]);

    const loadIssues = () => {
        if (selectedSite !== undefined) {
            if (selectedSite === "all") {
                sendPostRequest(
                    STATIONLOG_URL + "/search/all",
                    formatFilters(),
                    (response) => {
                        if (response.data.length === 0) {
                            setLoading(false);
                            setAtEnd(true);
                            return;
                        }
                        if (lastPage === 0) {
                            const sortedData = response.data.sort((a, b) =>
                                a.recordDate > b.recordDate ? -1 : 1
                            );
                            setIssues(sortedData);
                        } else {
                            const sortedData = issues
                                .concat(response.data)
                                .sort((a, b) =>
                                    a.recordDate > b.recordDate ? -1 : 1
                                );
                            setIssues(sortedData);
                        }
                        setLoading(false);
                    }
                );
            } else {
                sendPostRequest(
                    STATIONLOG_URL + "/search/" + selectedSite,
                    formatFilters(),
                    (response) => {
                        if (response.data.length === 0) {
                            setLoading(null);
                            setAtEnd(true);
                            return;
                        }
                        if (lastPage === 0) {
                            const sortedData = response.data.sort((a, b) =>
                                a.recordDate > b.recordDate ? -1 : 1
                            );
                            setIssues(sortedData);
                        } else {
                            const sortedData = issues
                                .concat(response.data)
                                .sort((a, b) =>
                                    a.recordDate > b.recordDate ? -1 : 1
                                );
                            setIssues(sortedData);
                        }
                        setLoading(false);
                    }
                );
            }
        }
    };

    const loadRecent = () => {
        if (selectedSite === "all") {
            sendPostRequest(
                STATIONLOG_URL + "/recent/all",
                { pageNumber: 0, pageSize: 10 },
                (response) => {
                    setRecentItems(
                        response.data.sort((a, b) =>
                            a.recordDate > b.recordDate ? -1 : 1
                        )
                    );
                }
            );
        } else {
            sendPostRequest(
                STATIONLOG_URL + "/recent/" + selectedSite,
                { pageNumber: 0, pageSize: 10 },
                (response) => {
                    setRecentItems(
                        response.data.sort((a, b) =>
                            a.recordDate > b.recordDate ? -1 : 1
                        )
                    );
                }
            );
        }
    };

    const refresh = () => {
        loadIssues();
        loadRecent();
    };

    const formatFilters = () => {
        let formattedFilters = { ...filters };
        formattedFilters.people =
            formattedFilters.people.size === 0
                ? null
                : Array.from(formattedFilters.people);
        formattedFilters["pageNumber"] = lastPage;
        return formattedFilters;
    };

    const mapItemsToEntry = () => {
        return issues.map((issue, index) => (
            <StationLogEntry
                key={"station-log-entry-" + index}
                issue={issue}
                setSelectedIssue={setSelectedIssue}
            />
        ));
    };

    const handleScroll = (event) => {
        const atBottom =
            event.target.scrollHeight -
                event.target.scrollTop -
                event.target.clientHeight <=
            20;
        if (atBottom && loading === false && !atEnd) {
            setLastPage(lastPage + 1);
        }
    };

    const renderStationLog = () => {
        if (editorOpen) {
            return (
                <StationLogIssueEditor
                    selectedSite={selectedSite}
                    toggle={toggleEditorOpen}
                    refresh={refresh}
                />
            );
        } else if (selectedIssue === undefined) {
            return (
                <div id="station-log" onScroll={handleScroll}>
                    <h2>Station Log</h2>
                    <RecentItems
                        issues={recentItems}
                        setSelectedIssue={setSelectedIssue}
                        loading={loading}
                    />
                    <h3>All Issues</h3>
                    <StationLogSearch
                        filters={filters}
                        setFilters={setFilters}
                        selectedSite={selectedSite}
                    />
                    <div id="station-log-entries">
                        {mapItemsToEntry()}
                        <StationLogEntryPlaceholder
                            hidden={issues.length > 0 || loading}
                        />
                        <StationLogCreateIssue toggle={toggleEditorOpen} />
                        <div
                            style={{
                                maxWidth: "800px",
                                width: "100%",
                                textAlign: "center",
                            }}
                            hidden={!loading}
                        >
                            <Spinner style={{ margin: "auto" }} />
                        </div>
                    </div>
                    <Button
                        id="station-log-new-issue-button"
                        color="primary"
                        onClick={toggleEditorOpen}
                    >
                        <img
                            className="inline-svg plus"
                            src={plus}
                            alt="Plus"
                        />
                    </Button>
                </div>
            );
        } else {
            return (
                <StationLogIssue
                    selectedIssue={selectedIssue}
                    setSelectedIssue={setSelectedIssue}
                    refresh={refresh}
                />
            );
        }
    };

    return (
        <StationLogContext.Provider value={{ siteDetails }}>
            {renderStationLog()}
        </StationLogContext.Provider>
    );
}

function RecentItems({ issues, setSelectedIssue, loading }) {
    const mapItemsToRecentItem = () => {
        const mostRecent = issues.sort((a, b) =>
            a.recordDate > b.recordDate ? -1 : 1
        );
        return mostRecent.map((issue, index) => (
            <RecentItem
                key={"station-log-recent-item" + index}
                issue={issue}
                setSelectedIssue={setSelectedIssue}
            />
        ));
    };

    return (
        <div id="station-log-recent-issues" dir="ltr">
            <StationLogRecentPlaceholder
                hidden={issues.length > 0 || loading}
            />
            {mapItemsToRecentItem()}
        </div>
    );
}

function RecentItem({ issue, setSelectedIssue }) {
    const truncateText = (text, length) => {
        if (text.length <= length) return text;
        for (var i = length; i > length - 20; i--) {
            if (text.charAt(i) === " ") {
                return (
                    text.substring(0, i) +
                    (text.substring(i - 1, i).match(/\W/) ? " " : "") +
                    "..."
                );
            }
        }
        return (
            text.substring(0, length - 2) +
            (text.substring(length - 3, length - 2).match(/\W/) ? " " : "") +
            "..."
        );
    };

    const getTimestamp = () => {
        if (issue === undefined) return null;
        const dateString = new Date(issue.recordDate).toLocaleString();
        const dateArray = dateString.split(/[,:/\s]+/g);
        return `${dateArray[3]}:${dateArray[4]} ${dateArray[6]}, ${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
    };

    const handleClick = (event) => {
        setSelectedIssue(issue.rootEntry);
    };

    const renderItem = () => {
        if (issue === undefined) return null;
        if (issue.parentLogId === null) {
            return (
                <RecentIssue
                    title={truncateText(issue.title, 40)}
                    aqmetAbbr={issue.aqmetAbbr}
                    detail={truncateText(issue.detail, 100)}
                    timestamp={getTimestamp()}
                    author={issue.author}
                    handleClick={handleClick}
                />
            );
        } else {
            return (
                <RecentReply
                    title={truncateText(issue.title, 40)}
                    aqmetAbbr={issue.aqmetAbbr}
                    detail={truncateText(issue.detail, 100)}
                    timestamp={getTimestamp()}
                    author={issue.author}
                    handleClick={handleClick}
                />
            );
        }
    };

    return renderItem();
}

function RecentIssue({
    handleClick,
    title,
    aqmetAbbr,
    detail,
    author,
    timestamp,
}) {
    return (
        <Card className="recent-issue" onClick={handleClick}>
            <CardTitle>
                <div className="recent-issue-title">{title}</div>
                <div className="recent-issue-subtitle">
                    <div className="recent-issue-abbr">{aqmetAbbr}</div>
                    <div className="recent-issue-type">New issue</div>
                </div>
            </CardTitle>
            <CardBody>
                <div className="recent-issue-detail">{detail}</div>
                <br />
                <div className="recent-issue-info">
                    <span>{formatDatetimeWithLocalTimezone(timestamp)}</span> by{" "}
                    <span className="station-log-username">{author}</span>
                    <span>
                        {" "}
                        <img src={user} className="user-icon" alt="User Icon" />
                    </span>
                </div>
            </CardBody>
        </Card>
    );
}

function RecentReply({
    handleClick,
    title,
    aqmetAbbr,
    detail,
    timestamp,
    author,
}) {
    return (
        <Card className="recent-reply" onClick={handleClick}>
            <CardTitle>
                <div className="recent-issue-title">{title}</div>
                <div className="recent-issue-subtitle">
                    <div className="recent-issue-abbr">{aqmetAbbr}</div>
                    <div className="recent-issue-type">New reply</div>
                </div>
            </CardTitle>
            <CardBody>
                <div className="station-log-reply-body">{detail}</div>
                <br />
                <div className="station-log-reply-info">
                    <span>{formatDatetimeWithLocalTimezone(timestamp)}</span> by{" "}
                    <span className="station-log-username">{author}</span>
                    <img src={user} className="user-icon" alt="User Icon" />
                </div>
            </CardBody>
        </Card>
    );
}

function StationLogSearch({ selectedSite, filters, setFilters }) {
    const [people, setPeople] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const { sendGetRequest } = useContext(RestContext);

    const toggleModal = () => setModalOpen(!modalOpen);

    useEffect(() => {
        if (selectedSite !== undefined) {
            if (selectedSite === "all") {
                sendGetRequest(STATIONLOG_URL + "/people/all", (response) => {
                    let data = response.data;
                    for (let index in data) {
                        if (data[index].fullName === "ARS Field Staff") {
                            data.splice(index, 1);
                            break;
                        }
                    }
                    data.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
                    setPeople(data);
                });
            } else {
                sendGetRequest(
                    STATIONLOG_URL + "/people/" + selectedSite,
                    (response) => {
                        let data = response.data;
                        for (let index in data) {
                            if (data[index].fullName === "ARS Field Staff") {
                                data.splice(index, 1);
                                break;
                            }
                        }
                        data.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
                        setPeople(data);
                    }
                );
            }
        }
    }, [selectedSite]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (value === "") {
            setFilters((values) => ({ ...values, [name]: null }));
        } else {
            setFilters((values) => ({ ...values, [name]: value }));
        }
    };

    const minDate = () => {
        return filters.startDate;
    };

    const maxDate = () => {
        if (filters.endDate === null) return today();
        return filters.endDate;
    };

    const handlePeople = (person) => {
        const value = Number(person.userId);
        let newPeople = new Set(filters.people);
        if (newPeople.has(value)) {
            newPeople.delete(value);
        } else {
            newPeople.add(value);
        }
        setFilters((values) => ({ ...values, people: newPeople }));
    };

    const personChecked = (person) => {
        if (filters === undefined) return false;
        const result = filters.people.has(person.userId);
        return result;
    };

    const mapPeopleToDropdownItem = () => {
        return people.map((person, index) => (
            <DropdownItem
                key={"people-dropdown-" + index}
                className="station-log-search-people-row"
                value={person.userId}
                onClick={() => {
                    handlePeople(person);
                }}
                toggle={false}
            >
                <span>{person.fullName}</span>
                <Input
                    type="checkbox"
                    value={personChecked(person)}
                    checked={personChecked(person)}
                    readOnly
                />
            </DropdownItem>
        ));
    };

    const mapPeopleToListItem = () => {
        return people.map((person, index) => (
            <div
                key={"people-list-" + index}
                className="station-log-search-mobile-people-item"
                onClick={() => {
                    handlePeople(person);
                }}
            >
                {person.fullName}
                <Input
                    type="checkbox"
                    value={personChecked(person)}
                    checked={personChecked(person)}
                    readOnly
                />
            </div>
        ));
    };

    const renderSearch = () => {
        if (checkMobile()) {
            return mobileSearch();
        } else {
            return desktopSearch();
        }
    };

    const clearSearchString = () => {
        setFilters((values) => ({ ...values, searchString: null }));
    };

    const searchStringEmpty = () => {
        if (filters === undefined) return true;
        if (filters.searchString === undefined || filters.searchString === null)
            return true;
        return false;
    };

    const getSearchString = () => {
        if (filters === undefined) return true;
        if (filters.searchString === undefined || filters.searchString === null)
            return "";
        return filters.searchString;
    };

    const desktopSearch = () => {
        return (
            <div id="station-log-search">
                <div id="station-log-search-input-wrapper">
                    <Input
                        id="station-log-search-input"
                        name="searchString"
                        type="text"
                        placeholder="Search..."
                        onChange={handleChange}
                        value={getSearchString()}
                    />
                    <Button
                        id="station-log-search-input-clear-button"
                        className="btn-close"
                        hidden={searchStringEmpty()}
                        onClick={clearSearchString}
                    />
                </div>
                <InputGroup id="station-log-search-date-range">
                    <InputGroupText>Dates</InputGroupText>
                    <Input
                        className="station-log-search-date"
                        name="startDate"
                        type="date"
                        onChange={handleChange}
                        max={maxDate()}
                        defaultValue={filters.startDate}
                    />
                    <Input
                        className="station-log-search-date"
                        name="endDate"
                        type="date"
                        onChange={handleChange}
                        min={minDate()}
                        max={today()}
                        defaultValue={filters.endDate}
                    />
                </InputGroup>
                <UncontrolledDropdown>
                    <DropdownToggle id="station-log-search-people-button">
                        People
                    </DropdownToggle>
                    <DropdownMenu>{mapPeopleToDropdownItem()}</DropdownMenu>
                </UncontrolledDropdown>
            </div>
        );
    };

    const mobileSearch = () => {
        return (
            <div id="station-log-search">
                <div id="station-log-search-input-wrapper">
                    <Input
                        id="station-log-search-input"
                        name="searchString"
                        type="text"
                        placeholder="Search..."
                        onChange={handleChange}
                        value={getSearchString()}
                    />
                    <Button
                        id="station-log-search-input-clear-button"
                        className="btn-close"
                        hidden={searchStringEmpty()}
                        onClick={clearSearchString}
                    />
                </div>
                <Button
                    id="station-log-search-filters-button"
                    color="primary"
                    onClick={toggleModal}
                >
                    Filters
                </Button>
                <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Filters</ModalHeader>
                    <ModalBody>
                        <h4>Dates</h4>
                        <InputGroup id="station-log-search-date-range">
                            <Input
                                className="station-log-search-date"
                                name="startDate"
                                type="date"
                                onChange={handleChange}
                                max={maxDate()}
                                defaultValue={filters.startDate}
                            />
                            <Input
                                className="station-log-search-date"
                                name="endDate"
                                type="date"
                                onChange={handleChange}
                                min={minDate()}
                                max={today()}
                                defaultValue={filters.endDate}
                            />
                        </InputGroup>
                        <br />
                        <h4>People</h4>
                        <div id="station-log-search-mobile-people-list">
                            {mapPeopleToListItem()}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    };

    return renderSearch();
}

function StationLogEntry({ issue, setSelectedIssue }) {
    const getTimestamp = () => {
        if (issue === undefined) return null;
        return formatDatetimeWithLocalTimezone(issue.recordDate);
    };

    const getAuthor = () => {
        if (issue === undefined) return null;
        return issue.author;
    };
    return (
        <div
            className="station-log-entry"
            onClick={() => setSelectedIssue(issue.logId)}
        >
            <div className="station-log-entry-title">{issue.title}</div>
            <div className="station-log-entry-abbr">{issue.siteAbbr}</div>
            <div className="station-log-entry-body">{issue.detail}</div>
            <div className="station-log-entry-info">
                <span>{getTimestamp()}</span> by{" "}
                <span className="station-log-username">{getAuthor()}</span>
                <span>
                    {" "}
                    <img src={user} className="user-icon" alt="User Icon" />
                </span>
            </div>
        </div>
    );
}

function StationLogCreateIssue({ toggle }) {
    return (
        <div id="station-log-create-issue" onClick={toggle}>
            Create new issue
        </div>
    );
}

function StationLogRecentPlaceholder({ hidden }) {
    return (
        <div id="recent-placeholder" hidden={hidden}>
            <span>No entries found.</span>
        </div>
    );
}

function StationLogEntryPlaceholder({ hidden }) {
    return (
        <div id="station-log-entry-placeholder" hidden={hidden}>
            <span>No entries found.</span>
        </div>
    );
}

export default StationLog;
