import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { default as utilIcons } from '../../resources/utilIcons';
import { colors } from "../../styles/colors";

import { RestContext } from "../../App";
import { ADMIN_URL } from "../../serverConfig";
import SiteTree from "../../components/SiteTree";

function UserAdmin(props) {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [formData, setFormData] = useState({});
    const [searchString, setSearchString] = useState('');
    const [matchingUsers, setMatchingUsers] = useState([]);
    const [changed, setChanged] = useState(false);
    const [siteSelectModalOpen, setSiteSelectModalOpen] = useState(false);

    const { sendGetRequest, sendPostRequest } = useContext(RestContext);

    const toggleSiteSelectModal = () => setSiteSelectModalOpen(!siteSelectModalOpen);

    useEffect(() => {
        sendGetRequest(ADMIN_URL + '/users', (response) => {
            setUsers(response.data.sort(sortUsers));
        });

        sendGetRequest(ADMIN_URL + '/roles', (response) => {
            let newRoles = {};
            for (let role of response.data) {
                newRoles[role.roleCode] = role.roleName;
            }
            setRoles(newRoles);
        });
    }, []);

    useEffect(() => {
        if (users && roles && searchString !== null) {
            const regex = new RegExp(searchString, 'i');
            setMatchingUsers(users.filter(user => user.fullName.match(regex) !== null));
        }
    }, [users, roles, searchString]);

    useEffect(() => {
        if (selectedUser) {
            loadUser();
        }
    }, [selectedUser]);

    const loadUser = () => {
        sendGetRequest(ADMIN_URL + '/user/' + selectedUser, (response) => {
            let newFormData = {
                userId: response.data.userId,
                name: response.data.userName,
                email: response.data.userEmail,
                role: response.data.userRole,
                active: response.data.active,
                notes: response.data.notes,
                sites: new Set(response.data.sites)
            }
            setFormData(newFormData);
            setChanged(false);
        });
    }

    const getName = () => {
        if (!formData.name) return '';
        return formData.name;
    }

    const getEmail = () => {
        if (!formData.email) return '';
        return formData.email;
    }

    const getRole = () => {
        if (!formData.role) return '';
        return formData.role;
    }

    const getActive = () => {
        if (formData.active === undefined) return false;
        return formData.active;
    }

    const getNotes = () => {
        if (!formData.notes) return '';
        return formData.notes;
    }

    const getSites = () => {
        if (!formData || !formData.sites) return new Set();
        return formData.sites;
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value }));
        setChanged(true);
    }

    const handleCheckbox = (event) => {
        setFormData(values => ({ ...values, active: !getActive() }));
        setChanged(true);
    }

    const handleEditButton = (event) => {
        const input = document.getElementById('user-admin-user-name');
        input.focus();
    }

    const isARS = (user) => {
        const regex = new RegExp('@air-resource.com', 'i');
        return user.userEmail.match(regex) !== null;
    }

    const sortUsers = (a, b) => {
        if (isARS(a) && !isARS(b)) return -1;
        else if (!isARS(a) && isARS(b)) return 11;
        else {
            return a.fullName.localeCompare(b.fullName);
        }
    }

    const mapUsers = () => {
        if (!matchingUsers) return null;
        return matchingUsers.map((user, index) =>
            <div
                key={"user-admin-result-" + index}
                className={"user-admin-result" + (selectedUser === user.userId ? ' selected' : '')}
                tabIndex={0}
                onClick={() => setSelectedUser(user.userId)}
            >
                <div className="user-admin-result-name">{user.fullName}</div>
                <div className="user-admin-result-ars" hidden={!isARS(user)}>ARS</div>
            </div>
        );
    }

    const mapRoles = () => {
        if (!roles) return null;
        return Object.keys(roles).map((role, index) =>
            <option key={'role-option-' + index} value={role}>{roles[role]}</option>
        );
    }

    const validName = () => {
        if (!formData || !formData.name) return false;
        return /^\w+(\s[()\w]+)+$/.test(formData.name);
    }

    const validEmail = () => {
        if (!formData || !formData.email) return false;
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email);
    }

    const toggleSite = (node, getParent) => {
        const siteId = node.nodeId;
        if (!formData || !formData.sites) return;
        const newFormData = { ...formData };
        if (newFormData.sites.has(siteId)) {
            newFormData.sites.delete(siteId);
            for (let parentNode = getParent(node); parentNode !== null; parentNode = getParent(parentNode)) {
                newFormData.sites.delete(parentNode.nodeId);
            }
        } else {
            newFormData.sites.add(siteId);
        }
        setFormData(newFormData);
        setChanged(true);
    }

    const toggleGroup = (node, getSubTree, getParent) => {
        const groupId = node.nodeId;
        const subtreeIds = getSubTree(node);
        if (!formData || !formData.sites) return;
        const newFormData = { ...formData };
        if (newFormData.sites.has(groupId)) {
            for (let siteId of subtreeIds) {
                newFormData.sites.delete(siteId);
            }
            for (let parentNode = getParent(node); parentNode !== null; parentNode = getParent(parentNode)) {
                newFormData.sites.delete(parentNode.nodeId);
            }
        } else {
            for (let siteId of subtreeIds) {
                newFormData.sites.add(siteId);
            }
        }
        setFormData(newFormData);
        setChanged(true);
    }

    const canSubmit = () => {
        return changed && validEmail() && validName();
    }

    const handleSubmit = () => {
        const payload = {
            userId: formData.userId,
            userName: formData.name,
            userEmail: formData.email,
            userRole: formData.role,
            active: formData.active,
            sites: Array.from(formData.sites),
            notes: formData.notes
        }

        sendPostRequest(ADMIN_URL + '/user/update', payload, (response) => {
            loadUser();
        });
    }

    return (
        <div id='user-admin'>
            <div id='user-admin-search-wrapper'>
                <Input id='user-admin-search' placeholder="Search..." onChange={(event) => setSearchString(event.target.value)} />
                <div id='user-admin-search-results-wrapper'>
                    <div id='user-admin-search-results'>
                        {mapUsers()}
                    </div>
                </div>
            </div>
            <div id='user-admin-user-info' hidden={selectedUser === undefined}>
                <div id='user-admin-user-info-header'>
                    <Input id='user-admin-user-name' name='name' value={getName()} onChange={handleChange} />
                    <Button className="user-admin-row-header-button" onClick={handleEditButton}>{utilIcons.pencil(colors['ars-neutral-500'], { height: '1.5rem', width: '1.5rem', flex: '0 0 auto' })}</Button>
                </div>
                <div className="user-admin-user-info-row">
                    <Label for='email'>Email: </Label>
                    <Input id='user-admin-user-email' type="email" name="email" value={getEmail()} onChange={handleChange} />
                </div>
                <div className="user-admin-user-info-row">
                    <Label for='role'>Role: </Label>
                    <select id='user-admin-user-role' name="role" value={getRole()} onChange={handleChange} >
                        {mapRoles()}
                    </select>
                </div>
                <div className="user-admin-user-info-row">
                    <Label for='sites'>Sites: </Label>
                    <Button id='user-admin-user-sites' name="sites" onClick={toggleSiteSelectModal}>Select Sites</Button>
                </div>
                <div className="user-admin-user-info-row">
                    <Label for='active'>Active: </Label>
                    <Input id='user-admin-user-active' type="checkbox" name="active" checked={getActive()} onClick={handleCheckbox} readOnly />
                </div>
                <div className="user-admin-user-info-row">
                    <Label for='notes'>Notes: </Label>
                    <Input id='user-admin-user-notes' type="textarea" name="notes" maxLength={500} value={getNotes()} onChange={handleChange} />
                    <div id='user-admin-row-textarea-counter'>{getNotes().length + ' / 500'}</div>
                </div>
                <Button id='user-admin-submit' className="submit-button" disabled={!canSubmit()} onClick={handleSubmit}>Save</Button>
            </div>
            <NoUser hidden={selectedUser !== undefined} />
            <SiteSelectModal
                isOpen={siteSelectModalOpen}
                toggle={toggleSiteSelectModal}
                sites={getSites()}
                toggleSite={toggleSite}
                toggleGroup={toggleGroup}
            />
        </div>
    )
}

function NoUser({ hidden }) {
    return (
        <div id='user-admin-none-selected' hidden={hidden}>
            {utilIcons.user(colors['ars-neutral-700'], {width: '100px', height: '100px'})}
            <div>Select a user.</div>
        </div>
    )
}

function SiteSelectModal({ isOpen, toggle, sites, toggleSite, toggleGroup }) {
    return (
        <Modal id='site-select-modal' isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Select Sites</ModalHeader>
            <ModalBody id='site-select-modal-body'> 
                <SiteTree lineComponent={SiteSelectLine} sites={sites} toggleSite={toggleSite} toggleGroup={toggleGroup} />
            </ModalBody>
            <ModalFooter>
                <Button className="submit-button" onClick={toggle}>Confirm</Button>
            </ModalFooter>
        </Modal>
    )
}

function SiteSelectLine({ sites, node, toggleSite, toggleGroup, getSubTree, getParent }) {

    const isChecked = () => {
        return sites.has(node.nodeId);
    }

    const onClick = (event) => {
        if (node.leaf) {
            toggleSite(node, getParent);
        } else {
            toggleGroup(node, getSubTree, getParent);
        }
    }

    return (
        <div className="site-select-line" onClick={onClick}>
            <div>{node.value}</div>
            <Input type='checkbox' checked={isChecked()} value={isChecked()} />
        </div>
    )
}

export default UserAdmin;