import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import "./styles/Documentation.css";

function DocumentationModal({ isOpen, toggle }) {
    return (
        <Modal id="documentation-modal" isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Documentation</ModalHeader>
            <ModalBody>
                <a href="DataViewChecklistAdministrationDocumentation.pdf" download>
                    <Button id="checklist-administration-button">Checklist Administration</Button>
                </a>
            </ModalBody>
        </Modal>
    );
}

export default DocumentationModal;
